import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Helmet } from "react-helmet";

// import required modules
import { Mousewheel } from "swiper/modules";

import rks1973 from "../../assets/Other/1973-2.jpg";
import rks1997 from "../../assets/Other/1997somajiguda2.jpg";
import rks2000 from "../../assets/Other/2000.jpeg";
import rks2000s from "../../assets/Other/2000secunderabadworkshop-2.png";
import rks2003 from "../../assets/Other/2003nampallyworkshop-2.jpg";
import rks2005 from "../../assets/Other/2005malakpetworkshop-2.jpg";
import rks2006 from "../../assets/Other/2006.jpg";
import rks2011 from "../../assets/Other/2011.jpeg";
import rks2015 from "../../assets/Other/2015.jpeg";
import rks2015s from "../../assets/Other/2015nexa.jpeg";
import rks2017 from "../../assets/Other/2017.jpeg";
import rks2018 from "../../assets/Other/2018.jpeg";
import rks2021 from "../../assets/Other/2021.jpeg";

const data = [
  {
    position: "0",
    year: "1973",
    img: rks1973,
    content: "Kinetic Dealership",
  },
  {
    position: "1",
    year: "1997",
    img: rks1997,
    content: "Maruti Suzuki Dealership Somajiguda (Showroom)",
  },
  {
    position: "2",
    year: "2000",
    img: rks2000,
    content: "Malakpet Showroom ",
  },
  {
    position: "3",
    year: "2000",
    img: rks2000s,
    content: "Secunderabad Workshop",
  },
  {
    position: "4",
    year: "2003",
    img: rks2003,
    content: "Nampally Workshop",
  },
  {
    position: "5",
    year: "2005",
    img: rks2005,
    content: "Malakpet Workshop",
  },
  {
    position: "6",
    year: "2006",
    img: rks2006,
    content: "Maruti Driving  School  ",
  },
  {
    position: "7",
    year: "2010",
    img: rks2000s,
    content: "Secunderabad Showroom",
  },
  {
    position: "8",
    year: "2011",
    img: rks2011,
    content: "Kushaiguda Showroom",
  },
  {
    position: "9",
    year: "2015",
    img: rks2015,
    content: "Kukatpally Workshop",
  },
  { position: "10", year: "2015", img: rks2015s, content: "NEXA Jubilee" },
  { position: "11", year: "2017", img: rks2017, content: "NEXA Lumbini " },
  { position: "12", year: "2018", img: rks2018, content: "True Value" },
  {
    position: "13",
    year: "2021",
    img: rks2021,
    content: "Shameerpet Showroom",
  },
];

const OusStory = () => {
  const [swiperIndex, setSwiperIndex] = useState(0);
  const swiperRef = useRef(null);

  const handleSlideChange = (swiper) => {
    setSwiperIndex(swiper.activeIndex);
  };

  const handleMarkerClick = (index) => {
    if (swiperRef && swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }
  };

  return (
    <div className="relative">
      <Helmet>
        <title>
          Saboo Group's Journey - From Humble Beginnings to Industry Pioneers
        </title>
        <meta
          name="title"
          content="Saboo Group's Journey - From Humble Beginnings to Industry Pioneers"
        />
        <meta
          name="description"
          content="Explore the inspiring journey of Saboo Group, from humble beginnings to becoming industry pioneers. Our story is a testament to dedication, growth, and success."
        />
      </Helmet>
      <div className="mx-auto h-[100vh] xl:container rounded-lg overflow-hidden mb-3 relative z-0">
        <Swiper
          direction={"vertical"}
          slidesPerView={1}
          spaceBetween={1}
          mousewheel={true}
          speed={800}
          onSlideChange={handleSlideChange}
          modules={[Mousewheel]}
          className="h-screen mySwiper"
          ref={swiperRef}
        >
          {data.map((x, i) => (
            <SwiperSlide key={i} className="flex flex-col items-center">
              <div className="w-full h-screen select-none ">
                <img src={x.img} alt="" className="w-screen h-screen" />
                <div className="absolute inset-0 flex flex-col justify-center text-white duration-200 bg-black bg-opacity-40 ">
                  <div className="px-4 text-2xl xl:text-3xl">{x.year}</div>
                  <div className="w-full px-4 py-2 m-1 text-4xl font-medium text-center xl:text-5xl">
                    {x.content}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="absolute  top-[30%] right-5 p-2 rounded-lg w-full">
        <div className="container flex flex-col items-end mx-auto ">
          {data.map((x, i) => (
            <div
              key={i}
              className="flex items-center justify-end -mt-1.5 gap-x-3 group select-none w-min whitespace-nowrap"
              onClick={() => handleMarkerClick(i)}
            >
              <div
                className={`${
                  parseFloat(swiperIndex) === i ? "visible" : "invisible"
                }  text-sm  group-hover:visible w-min text-white`}
              >
                {x.year}
              </div>
              <div
                className={`${
                  parseFloat(swiperIndex) === i
                    ? "w-7 bg-gradient-to-br from-[#213BC9] to-[#00AD8E] "
                    : "w-4 bg-black"
                } h-0.5 cursor-pointer`}
              ></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OusStory;
