import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Helmet } from "react-helmet";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";

import patternWhite from "../assets/Other/pattern.png";

const phoneRegExp = /^(\+91-|\+91|0)?\d{10}$/;

const key = "6Le8AaYpAAAAAPm8l9ZCLo-_BJJDD8nfV_qrOqPd";
// const secretKey = "6Lc0M8YoAAAAAMSbsBptkt2K_ag6ntwPMibYk952"

const proposelSchema = Yup.object().shape({
  name: Yup.string().required("Name is required*"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required*"),
  phone: Yup.string()
    .matches(phoneRegExp, "Invalid phone number")
    .required("Phone number is required*"),
  subject: Yup.string().required("Subject is required*"),
  company: Yup.string().required("Company name is required*"),
  message: Yup.string().required("Message is required*"),
});

const ContactUs = () => {
  const initialValues = {
    name: "",
    email: "",
    phone: "",
    company: "",
    subject: "",
    message: "",
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [capVal, setCapVal] = useState(null);

  const notify = () =>
    toast.success("Form submitted successfully.", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  return (
    <div className="">
      <Helmet>
        <title>
          Contact Saboo Group - Reach Out to Us for Your Automotive Needs
        </title>
        <meta
          name="title"
          content="Contact Saboo Group - Reach Out to Us for Your Automotive Needs"
        />
        <meta
          name="description"
          content="Have a query or need assistance? Contact Saboo Group today for all your automotive needs, from sales to service, we're here to help. Reach out to us via phone, email, or visit our showroom for personalized assistance."
        />
      </Helmet>
      <section>
        <div className="container grid grid-cols-1 px-2 pb-12 mx-auto lg:pb-20 pt-14 lg:grid-cols-3">
          <div className="pb-8 text-5xl lg:text-6xl ">Contact Us</div>
          <div className="space-y-6 text-lg lg:col-span-2 text-[#273043]">
            <div>Business deal or need any guidance?</div>
            <div>
              Connect with our expert team at Saboo RKS for guided assistance
              and answers to all your queries on our dedicated helpdesk.
            </div>
            <div>
              Saboo RKS Customer Care Number:{" "}
              <a
                href="tel:9848898488"
                className="mt-3 font-semibold whitespace-nowrap"
              >
                +91 98488 98488
              </a>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container px-1 mx-auto mb-12 overflow-hidden lg:mb-20 ">
          <iframe
            id="map"
            width="100%"
            height="400"
            // loading="lazy"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30453.936150076945!2d78.457337!3d17.424164000000005!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1e0a3e3dabcb8701!2sMaruti%20Suzuki%20Car%20Dealer%20(Somajiguda)!5e0!3m2!1sen!2sin!4v1624863206162!5m2!1sen!2sin"
            title="Saboo RKS Somajiguda Google Maps Location"
            className="rounded-2xl bg-[#F5F5F5]"
          ></iframe>
        </div>
      </section>
      <section>
        <div
          className=" pb-12  lg:pb-20 pt-14 sm:px-4 md:px-9 bg-[#F5F5F5] px-2 "
          style={{ backgroundImage: `url(${patternWhite})` }}
        >
          <div className="container grid grid-cols-1 mx-auto lg:grid-cols-2">
            <div className="pb-6 text-5xl lg:text-6xl">Write to us</div>
            <div className="space-y-6 text-lg ">
              <div className="max-w-xl mx-auto">
                <Formik
                  initialValues={initialValues}
                  validationSchema={proposelSchema}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    setLoading(true);
                    axios
                      .post(
                        "https://saboo-groups-backend.onrender.com/contactform",
                        values
                      )
                      .then((response) => {
                        if (response.data.status === true) {
                          notify();
                          setLoading(false);
                          resetForm({ values: initialValues });
                        } else {
                          setError(error.message);
                          setLoading(false);
                        }
                        setSubmitting(false);
                      })
                      .catch((error) => {
                        // console.error(error);
                        setError(error.message);
                        setLoading(false);
                        // alert(`Invalid data ~ ${error.message}`);
                        setSubmitting(false);
                      });
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form className="px-5 py-10 bg-white shadow-xl sm:p-10 md:max-w-3xl lg:p-14 rounded-2xl">
                      <div className="w-full mb-6">
                        <Field
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Name*"
                          required
                          className="w-full py-1 font-semibold border-b outline-none placeholder:text-md placeholder:text-black placeholder:font-medium"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className=" text-[#FF0000] font-medium "
                        />
                      </div>

                      <div className="w-full mb-6">
                        <Field
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Email*"
                          required
                          className="w-full py-1 font-semibold border-b outline-none placeholder:text-md placeholder:text-black placeholder:font-medium"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className=" text-[#FF0000] font-medium "
                        />
                      </div>

                      <div className="w-full mb-6">
                        <Field
                          type="text"
                          id="phone"
                          name="phone"
                          placeholder="Phone* "
                          required
                          className="w-full py-1 font-semibold border-b outline-none placeholder:text-md placeholder:text-black placeholder:font-medium"
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className=" text-[#FF0000] font-medium "
                        />
                      </div>
                      <div className="w-full mb-6">
                        <Field
                          type="text"
                          id="company"
                          name="company"
                          placeholder="Company* "
                          required
                          className="w-full py-1 font-semibold border-b outline-none placeholder:text-md placeholder:text-black placeholder:font-medium"
                        />
                        <ErrorMessage
                          name="company"
                          component="div"
                          className=" text-[#FF0000] font-medium "
                        />
                      </div>
                      <div className="w-full mb-6">
                        <Field
                          type="text"
                          id="subject"
                          name="subject"
                          placeholder="Subject*"
                          required
                          className="w-full py-1 font-semibold border-b outline-none placeholder:text-md placeholder:text-black placeholder:font-medium"
                        />
                        <ErrorMessage
                          name="subject"
                          component="div"
                          className=" text-[#FF0000] font-medium "
                        />
                      </div>
                      <div className="w-full mb-2">
                        <Field
                          as="textarea"
                          id="message"
                          name="message"
                          placeholder="Message*"
                          className="w-full py-1 font-semibold border-b outline-none placeholder:text-md placeholder:text-black placeholder:font-medium"
                        ></Field>
                        <ErrorMessage
                          name="message"
                          component="div"
                          className=" text-[#FF0000] font-medium "
                        />
                      </div>
                      <div className="mb-2 lg:mb-4">
                        <ReCAPTCHA
                          sitekey={key}
                          onChange={(val) => setCapVal(val)}
                          className="scale-[.8] sm:scale-100"
                        />
                        {!capVal && (
                          <div className="mt-1 text-sm">
                            Please show you're not a robot*
                          </div>
                        )}
                      </div>
                      <div className="w-full text-center">
                        <button
                          type="submit"
                          disabled={!capVal || isSubmitting}
                          className={`mt-4 rounded-lg bg-gradient-to-br from-[#213BC9] to-[#00AD8E] cursor-pointer  py-2 text-center text-lg  text-white focus:outline-none  w-full lg:w-1/2 hover:translate-x-1 hover:-translate-y-1 duration-150 `}
                        >
                          {loading ? "Submitting" : "Submit"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
    </div>
  );
};

export default ContactUs;
