import axios from "axios";
import React, { useEffect, useState } from "react";

const Form8 = ({ formData, setFormData, setCurrentStep, link }) => {

  const [entered, setEntered] = useState(-1);
  const handleSubmit = (e) => {
    setCurrentStep(9);
    e.preventDefault();
    setTimeout(() => {
      window.scrollTo(0, window.innerHeight * 9);
      if (link) {
        axios.post(
          `https://feedback-git-main-feedback-services-projects.vercel.app/feedback?phone=${formData.phone}`,
          formData
        );
      } else axios.post("https://saboo-nexa.onrender.com/feedback", formData);
    }, 500);
  };

  useEffect(() => {
    if (formData.recommendation !== "") {
      setCurrentStep(9);

      setTimeout(() => {
        window.scrollTo(0, window.innerHeight * 9);
        if (link) {
          axios.post(
            `https://feedback-git-main-feedback-services-projects.vercel.app/feedback?phone=${formData.phone}`,
            formData
          );
        } else axios.post("https://saboo-nexa.onrender.com/feedback", formData);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.recommendation]); // Removed handleSubmit from dependencies

  const handleRatingChange = (rating) => {
    setFormData({
      ...formData,
      recommendation: rating,
    });
    // setEntered(rating);
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="flex items-center justify-center h-screen px-2"
      >
        <div className="w-full max-w-xl md:max-w-3xl ">
          <p className="mb-2 text-lg font-bold text-center lg:mb-4 md:text-left">
            How Likely are you to recommend Saboo RKS Motors to your Friends,
            Family & Relatives based on your recent service Experience.
          </p>

          <div className="relative h-64 mt-6">
            {/* 5 */}
            <div
              onClick={() => handleRatingChange("5")}
              onMouseEnter={() => setEntered("5")}
              onMouseLeave={() => setEntered(-1)}
              className={`absolute top-0 -translate-x-1/2 left-1/2 ${entered === "5" ||
                  formData.recommendation === "" ||
                  formData.recommendation === "5"
                  ? "grayscale-0"
                  : "grayscale  opacity-40"
                }`}
            >
              <img
                src={require("../../assets/1x/Asset 60.png")}
                alt=""
                className="w-[67px] hover:scale-[0.8] cursor-pointer duration-500"
              />
            </div>
            {/* 4 */}
            <div
              onClick={() => handleRatingChange("4")}
              onMouseEnter={() => setEntered("4")}
              onMouseLeave={() => setEntered(-1)}
              className={`absolute -translate-x-1/2 -ml-14 top-1 left-1/2 hover:drop-shadow-2xl ${entered === "4" ||
                  formData.recommendation === "" ||
                  formData.recommendation === "4"
                  ? "grayscale-0"
                  : "grayscale  opacity-40"
                }`}
            >
              <img
                src={require("../../assets/1x/Asset 70.png")}
                alt=""
                className="w-[68px] hover:scale-[0.8] duration-500 cursor-pointer"
              />
            </div>
            {/* 6 */}
            <div
              onClick={() => handleRatingChange("6")}
              onMouseEnter={() => setEntered("6")}
              onMouseLeave={() => setEntered(-1)}
              className={`absolute ml-[54px] -translate-x-1/2 top-[5px] left-1/2 ${entered === "6" ||
                  formData.recommendation === "" ||
                  formData.recommendation === "6"
                  ? "grayscale-0"
                  : "grayscale  opacity-40"
                }`}
            >
              <img
                src={require("../../assets/1x/Asset 50.png")}
                alt=""
                className="w-[70px] hover:scale-[0.8]  cursor-pointer duration-500"
              />
            </div>{" "}
            {/* 7 */}
            <div
              onClick={() => handleRatingChange("7")}
              onMouseEnter={() => setEntered("7")}
              onMouseLeave={() => setEntered(-1)}
              className={`absolute ml-[88px] -translate-x-1/2 top-[37px] left-1/2 ${entered === "7" ||
                  formData.recommendation === "" ||
                  formData.recommendation === "7"
                  ? "grayscale-0"
                  : "grayscale  opacity-40"
                }`}
            >
              <img
                src={require("../../assets/1x/Asset 40.png")}
                alt=""
                className="w-[69px] hover:scale-[0.8] cursor-pointer duration-500"
              />
            </div>
            {/* 3 */}
            <div
              onClick={() => handleRatingChange("3")}
              onMouseEnter={() => setEntered("3")}
              onMouseLeave={() => setEntered(-1)}
              className={`absolute -ml-[92px] -translate-x-1/2 top-[33px] left-1/2 ${entered === "3" ||
                  formData.recommendation === "" ||
                  formData.recommendation === "3"
                  ? "grayscale-0"
                  : "grayscale  opacity-40"
                }`}
            >
              <img
                src={require("../../assets/1x/Asset 80.png")}
                alt=""
                className="w-[68px] hover:scale-[0.8] duration-500 cursor-pointer"
              />
            </div>
            {/* 2 */}
            <div
              onClick={() => handleRatingChange("2")}
              onMouseEnter={() => setEntered("2")}
              onMouseLeave={() => setEntered(-1)}
              className={`absolute -ml-[108px] -translate-x-1/2 top-[84px] left-1/2 ${entered === "2" ||
                  formData.recommendation === "" ||
                  formData.recommendation === "2"
                  ? "grayscale-0"
                  : "grayscale  opacity-40"
                }`}
            >
              <img
                src={require("../../assets/1x/Asset 90.png")}
                alt=""
                className="w-[54px] hover:scale-[0.8] duration-500 cursor-pointer"
              />
            </div>{" "}
            {/* 8 */}
            <div
              onClick={() => handleRatingChange("8")}
              onMouseEnter={() => setEntered("8")}
              onMouseLeave={() => setEntered(-1)}
              className={`absolute ml-[102px] -translate-x-1/2 top-[91px] left-1/2 ${entered === "8" ||
                  formData.recommendation === "" ||
                  formData.recommendation === "8"
                  ? "grayscale-0"
                  : "grayscale  opacity-40"
                }`}
            >
              <img
                src={require("../../assets/1x/Asset 30.png")}
                alt=""
                className="w-[54px] hover:scale-[0.8] duration-500 cursor-pointer"
              />
            </div>
            {/* 9 */}
            <div
              onClick={() => handleRatingChange("9")}
              onMouseEnter={() => setEntered("9")}
              onMouseLeave={() => setEntered(-1)}
              className={`absolute ml-[93px] -translate-x-1/2 top-[146px] left-1/2 ${entered === "9" ||
                  formData.recommendation === "" ||
                  formData.recommendation === "9"
                  ? "grayscale-0"
                  : "grayscale  opacity-40"
                }`}
            >
              <img
                src={require("../../assets/1x/Asset 20.png")}
                alt=""
                className="w-[64px] hover:scale-[0.8] duration-500 cursor-pointer"
              />
            </div>{" "}
            {/* 10 */}
            <div
              onClick={() => handleRatingChange("10")}
              onMouseEnter={() => setEntered("10")}
              onMouseLeave={() => setEntered(-1)}
              className={`absolute ml-[65px] -translate-x-1/2 top-[181px] left-1/2 ${entered === "10" ||
                  formData.recommendation === "" ||
                  formData.recommendation === "10"
                  ? "grayscale-0"
                  : "grayscale  opacity-40"
                }`}
            >
              <img
                src={require("../../assets/1x/Asset 10.png")}
                alt=""
                className="w-[68px] hover:scale-[0.8] duration-500 cursor-pointer"
              />
            </div>{" "}
            {/* 1 */}
            <div
              onClick={() => handleRatingChange("1")}
              onMouseEnter={() => setEntered("1")}
              onMouseLeave={() => setEntered(-1)}
              className={`absolute -ml-[102px] -translate-x-1/2 top-[140.3px] left-1/2 ${entered === "1" ||
                  formData.recommendation === "" ||
                  formData.recommendation === "1"
                  ? "grayscale-0"
                  : "grayscale  opacity-40"
                }`}
            >
              <img
                src={require("../../assets/1x/Asset 100.png")}
                alt=""
                className="w-[62px] hover:scale-[0.8] duration-500 cursor-pointer"
              />
            </div>
            {/* 0 */}
            <div
              onClick={() => handleRatingChange("0")}
              onMouseEnter={() => setEntered("0")}
              onMouseLeave={() => setEntered(-1)}
              className={`absolute -ml-[75px] -translate-x-1/2 top-[177px] left-1/2 ${entered === "0" ||
                  formData.recommendation === "" ||
                  formData.recommendation === "0"
                  ? "grayscale-0"
                  : "grayscale  opacity-40"
                }`}
            >
              <img
                src={require("../../assets/1x/Asset 110.png")}
                alt=""
                className="w-[68px] hover:scale-[0.8] duration-500 cursor-pointer"
              />
            </div>
          </div>
          <div className="flex justify-center text-sm italic -ml-7 gap-28 md:text-base">
            <p className="text-right ">
              Not at all <br />
              Likely
            </p>
            <p className="">
              Extremly <br />
              Likely
            </p>
          </div>

          <div className="flex items-center justify-center gap-5 mt-10">
            {/* <p className="italic ">
              Press <span className="font-bold uppercase">Enter</span>
            </p> */}
            {formData.recommendation && (
              <button
                type="submit"
                disabled={formData.recommendation === "" ? true : false}
                className="px-6 py-3 font-bold text-white uppercase bg-gray-900 rounded-md whitespace-nowrap "
              >
                Next
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default Form8;
