import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const HeroSection = () => {
  useEffect(() => {
    // AOS.init();
    AOS.init({ once: true });
  }, []);

  return (
    <>
      <div className="h-screen bg-[url(https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/Banner/saboogroup+home+page+banner1.webp)] bg-cover bg-center flex flex-col justify-end  ">
        <div
          data-aos="fade-right"
          data-aos-delay="400"
          data-aos-duration="500"
          className="container pb-20 mx-auto text-white"
        >
          <h1
          
            className="font-bold duration-200 text-8xl"
          >
            {/* <span className="hover:text-[#0A5290] cursor-pointer ">S</span>
            <span className="hover:text-[#0A5290] cursor-pointer">a</span>
            <span className="hover:text-[#0A5290] cursor-pointer">b</span>
            <span className="hover:text-[#0A5290] cursor-pointer">o</span>
            <span className="hover:text-[#0A5290] cursor-pointer">o</span>{" "}
            <span className="hover:text-[#ED1C24] cursor-pointer">G</span>
            <span className="hover:text-[#ED1C24] cursor-pointer">r</span>
            <span className="hover:text-[#ED1C24] cursor-pointer">o</span>
            <span className="hover:text-[#ED1C24] cursor-pointer">u</span>
            <span className="hover:text-[#ED1C24] cursor-pointer">p</span> */}
            Saboo Group
          </h1>
          <h4 className="text-5xl">Unleashing the Power of Possibility</h4>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
