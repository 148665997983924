import React from "react";
import { Helmet } from "react-helmet";

const CoreValue = () => {
  return (
    <>
      <Helmet>
        <title>
          Saboo Group Core Values - Guided by Integrity, Innovation, and
          Commitment
        </title>
        <meta
          name="title"
          content="Saboo Group Core Values - Guided by Integrity, Innovation, and Commitment"
        />
        <meta
          name="description"
          content="Discover the core values that define Saboo Group - Integrity, Innovation, and unwavering Commitment to excellence. These values shape our identity and guide our every endeavor."
        />
      </Helmet>
      <div className="bg-[#F5F5F5] py-16 px-4">
        <div className="container mx-auto sm:px-4 md:px-9 ">
          <div className="grid grid-cols-1 lg:grid-cols-3">
            <div className="pb-8 text-5xl lg:text-6xl ">Our Core Values</div>
            <div className="space-y-6 text-lg lg:col-span-2 text-[#273043]">
              <div>
                To Provide A Convenient, Hassle Free Automobile Purchase And
                Ownership Experience For Our Customers. To Facilitate The Most
                Cost Effective Attractive Financial Packages To All Our
                Customers. To Anticipate Our Customers' Need , Deliver
                Innovative And Outstanding Services To Their Delight .
              </div>
              <div>
                We at SABOO RKS are committed to:
                <ol className="list-[square] pl-6 sm:pl-10 pt-2">
                  <li className="pr-8 cursor-pointer hover:font-semibold w-min whitespace-nowrap">
                    Our customers
                  </li>
                  <li className="pr-8 cursor-pointer hover:font-semibold w-min whitespace-nowrap">
                    Our business performance
                  </li>
                  <li className="pr-8 cursor-pointer hover:font-semibold w-min whitespace-nowrap">
                    Our culture
                  </li>
                  <li className="pr-8 cursor-pointer hover:font-semibold w-min whitespace-nowrap">
                    Our values
                  </li>
                  <li className="pr-8 cursor-pointer hover:font-semibold w-min whitespace-nowrap">
                    Our operational excellence
                  </li>
                  <li className="pr-8 cursor-pointer hover:font-semibold w-min whitespace-nowrap">
                    Our innovation
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <div className="grid grid-cols-1 gap-4 py-16 text-center sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4">
          <div className="text-center">
            <img
              src={require("../../assets/Other/excellence.png")}
              alt=""
              srcSet=""
              className="h-20 mx-auto mb-4"
            />
            <div className="mb-2 text-xl font-semibold">
              Customer Excellence
            </div>
            <div>
              We strive for excellence in all areas of delivery, going above and
              beyond to exceed our customer's expectations. We aim to 'surprise
              and delight' in all customer dealings.
            </div>
          </div>
          <div className="text-center">
            <img
              src={require("../../assets/Other/innovation.png")}
              alt=""
              srcSet=""
              className="h-20 mx-auto mb-4 scale-105"
            />
            <div className="mb-2 text-xl font-semibold">
              Innovation & Continuous Improvement
            </div>
            <div>
              We have the courage and conviction to innovate and pioneer new
              methods, with an implicit drive to 'do it best.'
            </div>
          </div>
          <div className="text-center">
            <img
              src={require("../../assets/Other/honesty.png")}
              alt=""
              srcSet=""
              className="h-20 mx-auto mb-4"
            />
            <div className="mb-2 text-xl font-semibold">
              Honesty & Transparency
            </div>
            <div>
              We always maintain open and honest communications with clients and
              as a team.
            </div>
          </div>
          <div className="text-center">
            <img
              src={require("../../assets/Other/quality.png")}
              alt=""
              srcSet=""
              className="h-20 mx-auto mb-4"
            />
            <div className="mb-2 text-xl font-semibold">
              Uncompromised Quality
            </div>
            <div>
              We don't compromise on quality and believe that 'near enough' is
              never good enough.
            </div>
          </div>
        </div>
      </div>
      <div className="container px-4 py-12 mx-auto ">
        <div className="text-5xl lg:text-6xl lg:px-[12%]">
          Awards and Recognition
        </div>
        <div className="grid gap-4 text-center divide-y md:divide-y-0 md:grid-cols-3">
          <div>
            <img
              src={require("../../assets/Core Value/Royalplatinum.png")}
              alt="Royal Platinum"
              srcSet=""
              className="max-w-[90%]"
            />
            <div className="mb-2 text-xl font-semibold lg:text-2xl">
              <span className="text-3xl lg:text-4xl">6</span> times Royal
              Platinum band
            </div>
          </div>
          <div>
            <img
              src={require("../../assets/Core Value/alphadealership.png")}
              alt="Alpha Dealership"
              srcSet=""
              className="max-w-[90%]"
            />
            <div className="mb-2 text-xl font-semibold lg:text-2xl">
              <span className="text-3xl lg:text-4xl">3</span> times Alpha band
            </div>
          </div>
          <div>
            <img
              src={require("../../assets/Core Value/platimumclub.png")}
              alt="Platimum club"
              srcSet=""
              className="max-w-[90%]"
            />
            <div className="mb-2 text-xl font-semibold lg:text-2xl">
              <span className="text-3xl lg:text-4xl">6</span> times Platinum
              band
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoreValue;
