import React from "react";

const WelcomePage = ({ setCurrentStep, found }) => {
  return (
    <div className="flex items-center justify-center px-8 sm:h-screen group h-[91vh]">
      <div className="z-10 max-w-xl text-center">
        <p className="mb-2 font-sans text-3xl font-extrabold lg:mb-4 lg:text-4xl">
          Welcome to <br className="sm:hidden" /> Saboo RKS Service <br className="hidden lg:block" /> Customer Satisfaction Survey
        </p>
        <div className="my-6">
          <div className="h-0.5 w-full bg-black"></div>
        </div>
        <p className="pb-6 font-bold lg:pb-10 ">
          We value your feedback and aim to improve our service. Please take a
          few minutes to share your experience with the car servicing at our
          service center.
        </p>
        <div>
         {found ? <button
          onClick={() => setCurrentStep(0)}
          className="px-6 py-3 mt-5 font-sans text-xl font-bold text-white uppercase transition-all duration-200 bg-gray-900 rounded-full whitespace-nowrap hover:scale-95 lg:text-2xl"
        >
          Start Survey
        </button>: <div className="italic">Fetching your information, please wait...</div>}
        </div>
        
      </div>
      <div className="absolute inset-0 left-0 z-0 w-full h-full p-5 overflow-hidden bg-white md:p-12 lg:p-14">
        <div className="w-full h-full bg-[#363388] rounded-[3rem] flex justify-end items-end bg-opacity-10 ">
          <div className=" translate-x-1/2 translate-y-1/2 bg-white rounded-full w-[20rem] h-[20rem] md:w-[25rem] md:h-[25rem] lg:w-[30rem] lg:h-[30rem] p-2  delay-300  duration-500 ">
            <div className="w-full h-full rounded-full bg-[#363388] bg-opacity-10"></div>
          </div>
        </div>

        {/* <img src={require("../../assets/Other/feedback_back.png")} alt="" srcset="" /> */}
      </div>
    </div>
  );
};

export default WelcomePage;
