import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";

const PersonalData = ({ formData, setFormData, setCurrentStep }) => {
  const handleSubmit = async (values) => {
    try {
      // values.vehicleNumber = `${values.stateCode}${values.twoDigitNumber}${values.character}${values.fourDigitNumber}`;
      setFormData({
        ...formData,
        name: values.name,
        phone: values.phone,
        location: values.location,
        vehicleNumber: values.vehicleNumber,
        // vehicleNumber: values.vehicleNumber.trim().toUpperCase(),
      });
      axios.post(
        `https://feedback-git-main-feedback-services-projects.vercel.app/feedback?phone=${values.phone}`,
        {
          ...formData,
          name: values.name,
          phone: values.phone,
          location: values.location,
          vehicleNumber: values.vehicleNumber,
          // vehicleNumber: values.vehicleNumber.trim().toUpperCase(),
        }
      );
      setCurrentStep(1);
      sessionStorage.setItem("currentStep", 1);
      sessionStorage.setItem("personalData", JSON.stringify(values));
      // } else {

      // }
    } catch (error) {
      console.log(error);
    } finally {
      // setCurrentStep(1);
      // sessionStorage.setItem("currentStep", 1);
      // sessionStorage.setItem("personalData", JSON.stringify(values));
    }
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(" Name is required")
      .matches(/^[a-zA-Z., ]+$/, "Invalid name"),
    phone: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Phone number must be 10 digits")
      .required("Phone number is required"),

    location: Yup.string()
      .required("Location is required")
      .matches(/^[a-zA-Z., ]+$/, "Invalid location"),
    vehicleNumber: Yup.string().required("Vehicle number is required"),
  });

  const initialValues = {
    name: formData.name || "",
    location: formData.location || "",
    phone: formData.phone || "",
    vehicleNumber: formData.vehicleNumber || "",

  };
  let locationValue = formData.location || "";
  if (locationValue !== "") {
    locationValue = locationValue.toUpperCase();
  }

  return (
    <div className="w-full h-screen flex items-center justify-center bg-[#f5f5f5] ">
      <div className="w-full px-2 sm:max-w-md md:max-w-lg bg-red-5 ">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="w-full mb-2 md:mb-4 lg:mb-6">
                <label className="mb-1 font-bold" htmlFor="name ">
                  Full Name*
                </label>

                <Field
                  type="text"
                  name="name"
                  className={`w-full p-2 bg-white border rounded-lg ${initialValues.name === formData.name &&
                    initialValues.name !== "" &&
                    "bg-slate-200  cursor-not-allowed border-2 "
                    }`}
                  placeholder="Enter your full name"
                  disabled={
                    initialValues.name === formData.name &&
                    initialValues.name !== ""
                  }
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-red-700 "
                />
              </div>
              <div className="w-full mb-2 md:mb-4 lg:mb-6">
                <label className="mb-1 font-bold" htmlFor="phone">
                  Phone Number*
                </label>
                <Field
                  type="text"
                  name="phone"
                  maxLength={10}
                  disabled={
                    initialValues.phone === formData.phone &&
                    initialValues.phone !== ""
                  }
                  className={`w-full p-2 bg-white border rounded-lg ${initialValues.phone === formData.phone &&
                    initialValues.phone !== "" &&
                    "bg-slate-200  cursor-not-allowed border-2 "
                    }`}
                  placeholder="Enter your phone number"
                />
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="text-red-700 "
                />
              </div>
              <div className="w-full mb-2 md:mb-4 lg:mb-6">
                <label className="mb-1 font-bold" htmlFor="vehicleNumber">
                  Vehicle Number*
                </label>

                <Field
                  type="text"
                  name="vehicleNumber"
                  maxLength={10}
                  minLength={7}
                  disabled={
                    initialValues.vehicleNumber === formData.vehicleNumber &&
                    initialValues.vehicleNumber !== ""
                  }
                  className={`w-full p-2 uppercase bg-white border rounded-lg ${initialValues.vehicleNumber === formData.vehicleNumber &&
                    initialValues.vehicleNumber !== "" &&
                    "bg-slate-200  cursor-not-allowed border-2 "
                    }`}
                  placeholder="eg: TL11XXXXXX"
                />

                <ErrorMessage
                  name="vehicleNumber"
                  component="div"
                  className="text-red-700 "
                />
              </div>{" "}
              <div className="w-full mb-2 md:mb-4 lg:mb-6">
                <label className="mb-1 font-bold" htmlFor="location">
                  Workshop Location*
                </label>
                <Field
                  as="select"
                  name="location"
                  // value={locationValue} // Use value instead of defaultValue
                  defaultValue={locationValue}
                  // onChange={(e) => setLocationValue(e.target.value)}
                  className="w-full p-2 bg-white border-2 rounded-lg"
                >
                  <option value="">Select your location</option>
                  <option value="HAFEEZPET">HAFEEZPET</option>
                  <option value="KOMPALLY">KOMPALLY</option>
                  <option value="SOMAJIGUDA">SOMAJIGUDA</option>
                  <option value="MOOSARAMBAGH">MOOSARAMBAGH</option>
                  <option value="KODANGAL">KODANGAL</option>
                  <option value="UPPAL">UPPAL</option>
                  <option value="TADBUND">TADBUND</option>
                  <option value="NAMPALLY">NAMPALLY</option>
                  <option value="KUSHAIGUDA">KUSHAIGUDA</option>
                  <option value="KUKATPALLY">KUKATPALLY</option>
                  <option value="SHAMIRPET">SHAMIRPET</option>
                </Field>
                <ErrorMessage
                  name="location"
                  component="div"
                  className="text-red-700"
                />
              </div>
              {/*  <div className="mb-1 font-bold">
                <p>Vehicle number</p>
              </div>
               <div className="grid grid-cols-4 gap-0.5 lg:gap-1">
                <div>
                 
                  <Field
                    type="text"
                    name="stateCode"
                    maxLength={2}
                    className="w-full p-2 bg-white border-2 rounded-l-lg"
                    placeholder="eg. TS"
                    // pattern="[A-Za-z]{2}"
                  />
                  <ErrorMessage
                    name="stateCode"
                    component="div"
                    className="text-sm text-red-700"
                  />
                </div>

                <div>
                 
                  <Field
                    type="text"
                    name="twoDigitNumber"
                    maxLength={2}
                    className="w-full p-2 bg-white border-2 "
                    placeholder="eg. 11"
                    // pattern="[0-9]{2}"
                  />
                  <ErrorMessage
                    name="twoDigitNumber"
                    component="div"
                    className="text-sm text-red-700"
                  />
                </div>

                <div>
                  
                  <Field
                    type="text"
                    name="character"
                    maxLength={2}
                    className="w-full p-2 bg-white border-2 "
                    placeholder="eg: XZ"
                    // pattern="[A-Za-z]{0,2}"
                  />
                  <ErrorMessage
                    name="character"
                    component="div"
                    className="text-sm text-red-700"
                  />
                </div>

                <div>
                 
                  <Field
                    type="text"
                    name="fourDigitNumber"
                    maxLength={4}
                    className="w-full p-2 bg-white border-2 rounded-r-lg"
                    placeholder="eg. 8456"
                    pattern="[0-9]{4}"
                  />
                  <ErrorMessage
                    name="fourDigitNumber"
                    component="div"
                    className="text-sm text-red-700"
                  />
                </div>
              </div> */}
              <div className="flex items-center gap-5 mt-10 lg:justify-end">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="px-6 py-3 font-bold text-white uppercase bg-gray-900 rounded-md whitespace-nowrap"
                >
                  Next
                </button>{" "}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PersonalData;
