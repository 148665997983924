import { useEffect, useState } from "react";
import { Dialog, Disclosure, Popover } from "@headlessui/react";

import { BsChevronDown } from "react-icons/bs";
import { AiOutlineMenu } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { FaRegUser } from "react-icons/fa";

const products1 = [
  [
    {
      name: "Our Story",
      href: "/story",
    },
    {
      name: "About Us",

      href: "/about",
    },
    {
      name: "Management",

      href: "/management",
    },
    {
      name: "Core Value",

      href: "/core-value",
    },
  ],
  [
    {
      name: "Our Business",
      href: "/business",
    },
    {
      name: "Automobile",
      href: "/automobile",
    },
    {
      name: "Hospitality",
      href: "/hospitality",
    },
    {
      name: "Real Esate",
      href: "/real-estate",
    },
    {
      name: "Healthcare & Wellness",
      href: "/healthcare",
    },
  ],
  [
    {
      name: "Gallery",
      href: "/gallery",
    },
    {
      name: "Awards",
      href: "/awards",
    },
  ],
];

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  // const [openDisclosureIndex, setOpenDisclosureIndex] = useState(0);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  // const [scrollTop, setScrollTop] = useState(0);

  // const onScroll = () => {
  //   const winScroll = document.documentElement.scrollTop;
  //   const height =
  //     document.documentElement.scrollHeight -
  //     document.documentElement.clientHeight;
  //   const scrolled = (winScroll / height) * 100;

  //   setScrollTop(scrolled);
  // };
  // useEffect(() => {
  //   window.addEventListener("scroll", onScroll);

  //   return () => window.removeEventListener("scroll", onScroll);
  // }, []);

  return (
    <header className={`bg-white shadow fixed w-full z-10  `}>
      {/* <header className={`${scrollTop>10?"bg-white text-black ":"  "} fixed w-full z-10`}> */}
      <nav
        className="container flex items-center justify-between h-full p-2 mx-auto lg:p-3 lg:px-4"
        aria-label="Global"
      >
        <div className="flex ">
          <Link to="/" className="">
            <span className="sr-only">Your Company</span>
            <img
              className="w-auto h-12 md:h-14"
              src={require("../../assets/Hompage/logo.png")}
              alt=""
              srcSet=""
            />
            {/* <img className="w-auto h-8" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="" /> */}
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 "
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <AiOutlineMenu className="w-6 h-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-10">
          <Popover className="relative group">
            <div className="flex items-center font-semibold leading-6 appearance-none gap-x-1 focus:outline-none group-hover:cursor-pointer group ">
              Who We Are
              <BsChevronDown
                className="flex-none w-4 h-4 ml-1 duration-200 group-hover:rotate-180"
                aria-hidden="true"
              />
            </div>

            <div className="absolute z-10 invisible overflow-hidden bg-white shadow-lg w-min -left-4 top-6 rounded-3xl ring-1 ring-gray-900/5 group-hover:visible">
              <div className="p-4">
                {products1[0].map((item) => (
                  <div
                    key={item.name}
                    className="relative flex items-center p-2 text-black rounded-lg group gap-x-6 hover:bg-gray-50"
                  >
                    <div className="flex-auto">
                      <Link to={item.href} className="block font-semibold ">
                        {item.name}
                        <span className="absolute inset-0" />
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Popover>
          <Popover className="relative group">
            <div className="flex items-center font-semibold leading-6 appearance-none gap-x-1 focus:outline-none group-hover:cursor-pointer group ">
              What We Do
              <BsChevronDown
                className="flex-none w-4 h-4 ml-1 duration-200 group-hover:rotate-180"
                aria-hidden="true"
              />
            </div>

            <div className="absolute z-10 invisible overflow-hidden bg-white shadow-lg w-min -left-4 top-6 rounded-3xl ring-1 ring-gray-900/5 group-hover:visible">
              <div className="p-4">
                <div className="relative flex items-center p-2 text-black rounded-lg group gap-x-6 hover:bg-gray-50">
                  <div className="flex-auto">
                    <Link
                      to="/business"
                      className="block font-semibold whitespace-nowrap"
                    >
                      Our Business
                      <span className="absolute inset-0" />
                    </Link>
                  </div>
                </div>
                <div className="relative flex items-center p-2 text-black rounded-lg group gap-x-6 hover:bg-gray-50">
                  <div className="flex-auto">
                    <Link
                      to="/automobile                      "
                      className="block font-semibold whitespace-nowrap"
                    >
                      Automobile
                      <span className="absolute inset-0" />
                    </Link>
                  </div>
                </div>
                <div className="relative flex items-center p-2 text-black rounded-lg group gap-x-6 hover:bg-gray-50">
                  <div className="flex-auto">
                    <Link
                      to="/hospitality"
                      className="block font-semibold whitespace-nowrap"
                    >
                      Hospitality
                      <span className="absolute inset-0" />
                    </Link>
                  </div>
                </div>
                <div className="relative flex items-center p-2 text-black rounded-lg group gap-x-6 hover:bg-gray-50">
                  <div className="flex-auto">
                    <Link
                      to="/real-estate"
                      className="block font-semibold whitespace-nowrap"
                    >
                      Real eState
                      <span className="absolute inset-0" />
                    </Link>
                  </div>
                </div>
                <div className="relative flex items-center p-2 text-black rounded-lg group gap-x-6 hover:bg-gray-50">
                  <div className="flex-auto">
                    <Link to="/healthcare" className="block font-semibold ">
                      Healthcare & Wellness
                      <span className="absolute inset-0" />
                    </Link>
                  </div>
                </div>
                {/* <div className="relative flex items-center p-2 text-sm text-black rounded-lg group gap-x-6 hover:bg-gray-50">
                  <div className="flex-auto">
                    <a
                      href="https://www.saboomaruti.in/"
                      target="_blank"
                      rel="noreferrer"
                      className="block font-semibold whitespace-nowrap"
                    >
                      Saboo Arena
                      <span className="absolute inset-0" />
                    </a>
                  </div>
                </div>
                <div className="relative flex items-center p-2 text-sm text-black rounded-lg group gap-x-6 hover:bg-gray-50">
                  <div className="flex-auto">
                    <a
                      href="https://www.saboonexa.in/"
                      target="_blank"
                      rel="noreferrer"
                      className="block font-semibold whitespace-nowrap"
                    >
                      Saboo Nexa
                      <span className="absolute inset-0" />
                    </a>
                  </div>
                </div>
                <div className="relative flex items-center p-2 text-sm text-black rounded-lg group gap-x-6 hover:bg-gray-50">
                  <div className="flex-auto">
                    <a
                      href="https://www.sabootruevalue.in/"
                      target="_blank"
                      rel="noreferrer"
                      className="block font-semibold whitespace-nowrap"
                    >
                      Saboo True Value
                      <span className="absolute inset-0" />
                    </a>
                  </div>
                </div>
                <div className="relative flex items-center p-2 text-sm text-black rounded-lg group gap-x-6 hover:bg-gray-50">
                  <div className="flex-auto">
                    <a
                      href="https://www.sabooautozone.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="block font-semibold whitespace-nowrap"
                    >
                      Saboo AutoZone
                      <span className="absolute inset-0" />
                    </a>
                  </div>
                </div>
                <div className="relative flex items-center p-2 text-sm text-black rounded-lg group gap-x-6 hover:bg-gray-50">
                  <div className="flex-auto">
                    <a
                      href="https://www.saboocommercial.in/"
                      target="_blank"
                      rel="noreferrer"
                      className="block font-semibold whitespace-nowrap"
                    >
                      Saboo Commercial
                      <span className="absolute inset-0" />
                    </a>
                  </div>
                </div>
                <div className="relative flex items-center p-2 text-sm text-black rounded-lg group gap-x-6 hover:bg-gray-50">
                  <div className="flex-auto">
                    <a
                      href="https://www.hotelinnercircle.in/"
                      target="_blank"
                      rel="noreferrer"
                      className="block font-semibold whitespace-nowrap"
                    >
                      Hotel Inner Circle
                      <span className="absolute inset-0" />
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </Popover>
          <Popover className="relative group">
            <div className="flex items-center font-semibold leading-6 appearance-none gap-x-1 focus:outline-none group-hover:cursor-pointer group ">
              Media
              <BsChevronDown
                className="flex-none w-4 h-4 ml-1 duration-200 group-hover:rotate-180"
                aria-hidden="true"
              />
            </div>

            <div className="absolute z-10 invisible overflow-hidden bg-white shadow-lg w-28 -left-4 top-6 rounded-3xl ring-1 ring-gray-900/5 group-hover:visible ">
              <div className="p-4">
                <div className="relative flex items-center p-2 text-black rounded-lg group gap-x-6 hover:bg-gray-50 ">
                  <div className="flex-auto">
                    <Link to="/awards" className="block font-semibold ">
                      Awards
                      <span className="absolute inset-0" />
                    </Link>
                  </div>
                </div>
                <div className="relative flex items-center p-2 text-black rounded-lg group gap-x-6 hover:bg-gray-50">
                  <div className="flex-auto">
                    <Link to="/gallery" className="block font-semibold ">
                      Gallery
                      <span className="absolute inset-0" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Popover>

          {/* <Link to="/business" className="font-semibold leading-6 ">
            What We Do
          </Link> */}
          {/* <Link to="/awards" className="font-semibold leading-6 ">
            Media
          </Link> */}
          <Link to="/careers" className="font-semibold leading-6 ">
            Careers
          </Link>
        </Popover.Group>
        <div className="hidden lg:flex lg:justify-end">
          <Link
            to="/contact"
            className="flex items-center gap-2 font-semibold leading-6"
          >
            Contact Us <FaRegUser className="text-lg text-gray-600 " />
          </Link>
        </div>
      </nav>

      {/* mobile */}
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full px-6 py-6 overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="">
              <span className="sr-only">Saboo Group</span>
              <img
                className="w-auto h-12"
                src="https://www.saboogroups.com/assets/images/black-logo.png"
                alt=""
              />
            </Link>
            <button
              type="button"
              className="text-3xl rounded-md "
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>x
            </button>
          </div>
          <div className="flow-root mt-6">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="pt-6 pb-2 space-y-2">
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7  hover-bg-gray-50">
                        Who We Are
                        <BsChevronDown
                          className={`h-4 w-4 flex-none duration-100 ${
                            open ? "rotate-180" : "rotate-0"
                          }`}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2 text-black">
                        {products1[0].map((item, i) => (
                          <Link
                            key={i}
                            to={item.href}
                            onClick={() => {
                              // setOpenDisclosureIndex(0);
                              setMobileMenuOpen(false);
                            }}
                          >
                            <Disclosure.Button
                              key={item.name}
                              className="block w-full py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-left rounded-lg hover:bg-gray-100"
                            >
                              {item.name}
                            </Disclosure.Button>
                          </Link>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                {/* <a
                  href="/"
                  className="block px-3 py-2 -mx-3 text-base font-semibold leading-7 rounded-lg hover-bg-gray-50"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Features
                </a>
                <a
                  href="/"
                  className="block px-3 py-2 -mx-3 text-base font-semibold leading-7 rounded-lg hover-bg-gray-50"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Marketplace
                </a>
                <a
                  href="/"
                  className="block px-3 py-2 -mx-3 text-base font-semibold leading-7 rounded-lg hover-bg-gray-50"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Company
                </a> */}
              </div>
              <div className="py-2 space-y-2">
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7  hover-bg-gray-50">
                        What We Do
                        <BsChevronDown
                          className={`h-4 w-4 flex-none duration-100 ${
                            open ? "rotate-180" : "rotate-0"
                          }`}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2 text-black">
                        {products1[1].map((item, i) => (
                          <Link
                            key={i}
                            to={item.href}
                            onClick={() => {
                              // setOpenDisclosureIndex(1);
                              setMobileMenuOpen(false);
                            }}
                          >
                            <Disclosure.Button
                              key={item.name}
                              className="block w-full py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-left rounded-lg hover:bg-gray-100"
                            >
                              {item.name}
                            </Disclosure.Button>
                          </Link>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
              <div className="py-2 space-y-2">
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7  hover-bg-gray-50">
                        Media
                        <BsChevronDown
                          className={`h-4 w-4 flex-none duration-100 ${
                            open ? "rotate-180" : "rotate-0"
                          }`}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2 text-black">
                        {products1[2].map((item, i) => (
                          <Link
                            key={i}
                            to={item.href}
                            onClick={() => {
                              // setOpenDisclosureIndex(2);
                              setMobileMenuOpen(false);
                            }}
                          >
                            <Disclosure.Button
                              key={item.name}
                              className="block w-full py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-left rounded-lg hover:bg-gray-100"
                            >
                              {item.name}
                            </Disclosure.Button>
                          </Link>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>

                {/* <a
                  href="/"
                  className="block px-3 py-2 -mx-3 text-base font-semibold leading-7 rounded-lg hover-bg-gray-50"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Features
                </a>
                <a
                  href="/"
                  className="block px-3 py-2 -mx-3 text-base font-semibold leading-7 rounded-lg hover-bg-gray-50"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Marketplace
                </a>
                <a
                  href="/"
                  className="block px-3 py-2 -mx-3 text-base font-semibold leading-7 rounded-lg hover-bg-gray-50"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Company
                </a> */}
              </div>
              <div className="py-6">
                <Link
                  to="/contact"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7  hover-bg-gray-50"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
