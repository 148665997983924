import React from "react";
// import HomeSlider from "../components/Home/HomeSlider";
import LegacyVision from "../components/Home/LegacyVision";
import Bussiness from "../components/Home/Bussiness";
import Activity from "../components/Home/Activity";
import Trending from "../components/Home/Trending";
import Leading from "../components/Home/Leading";
import LeadingSlider from "../components/Home/LeadingSlider";
import { Helmet } from "react-helmet";
import BrandsSlider from "../components/Home/BrandSlider";
import HeroSection from "../components/Home/HeroSection";

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>
          Saboo Group - Elevating Excellence Across Industries | Leaders in
          Innovation and Commitment
        </title>
        <meta
          name="title"
          content="Saboo Group - Elevating Excellence Across Industries | Leaders in Innovation and Commitment"
        />
        <meta
          name="description"
          content="Explore a world of innovation with Saboo Group. Pioneering excellence across diverse industries, we are committed to shaping a brighter future through innovation and dedication..
          "
        />
        {/* <meta name="keywords" content="Nexa Awards, Maruti Awards" /> 
        <meta name="author" content="Broaddcast" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.saboogroup.co.in/" />
        <meta
          property="og:title"
          content="Saboo Group - Elevating Excellence Across Industries | Leaders in Innovation and Commitment"
        />
        <meta
          property="og:description"
          content="Explore a world of innovation with Saboo Group. Pioneering excellence across diverse industries, we are committed to shaping a brighter future through innovation and dedication."
        /> */}
      </Helmet>
      <HeroSection />
      <LegacyVision />
      <Bussiness />
      <BrandsSlider />
      <Activity />
      <Trending />
      <Leading />
      <LeadingSlider />
    </div>
  );
};

export default Home;
