import axios from "axios";
import React, { useEffect } from "react";

import {
  FaFaceAngry,
  FaFaceFrown,
  FaFaceGrinWide,
  FaFaceMeh,
  FaFaceSmile,
  FaRegFaceAngry,
  FaRegFaceFrown,
  FaRegFaceGrinWide,
  FaRegFaceMeh,
  FaRegFaceSmile,
} from "react-icons/fa6";

const Form1 = ({ formData, setFormData, setCurrentStep, link }) => {
  const handleSubmit = (e) => {
    setCurrentStep(2);
    e.preventDefault();
    setTimeout(() => {
      window.scrollTo(0, window.innerHeight * 2);
      if (link) {
        axios.post(
          `https://feedback-git-main-feedback-services-projects.vercel.app/feedback?phone=${formData.phone}`,
          formData
        );
      } else axios.post("https://saboo-nexa.onrender.com/feedback", formData);
    }, 500);
  };

  useEffect(() => {
    // Check if both overAllPerformance and preferingSabooRKS are not empty
    if (
      formData.overAllPerformance !== "" &&
      formData.preferingSabooRKS !== ""
    ) {
      setCurrentStep(2);
      setTimeout(() => {
        window.scrollTo(0, window.innerHeight * 2);
        console.log(formData);
        if (link) {
          axios.post(
            `https://feedback-git-main-feedback-services-projects.vercel.app/feedback?phone=${formData.phone}`,
            formData
          );
        } else axios.post("https://saboo-nexa.onrender.com/feedback", formData);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.overAllPerformance, formData.preferingSabooRKS]); // Removed handleSubmit from dependencies

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="flex items-center justify-center h-screen px-2 "
      >
        <div className="max-w-xl md:max-w-3xl lg:w-full ">
          <p className="mb-2 text-lg font-bold text-center lg:mb-4 md:text-left">
            Keeping in mind your interactions & experiences at Saboo RKS Service
            center, how would you rate overall performance of service center?
          </p>
          <div className="flex justify-center gap-x-2 lg:justify-start">
            <div
              className={`flex gap-5 mb-2 rounded-lg  border-2 md:w-1/5 bg-white ${formData.overAllPerformance === "Excellent"
                  ? "  border-green-600 "
                  : " border-gray-200   "
                } `}
            >
              <label
                htmlFor="overAllPerformanceExcellent"
                className="flex items-center w-full p-3 cursor-pointer "
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-green-600">
                  {formData.overAllPerformance !== "Excellent" ? (
                    <FaRegFaceGrinWide className="" />
                  ) : (
                    <FaFaceGrinWide />
                  )}
                </div>
                <span className={`hidden w-full ml-2  select-none md:block  `}>
                  {/* <span
                  className={`hidden w-full ml-2  select-none md:block ${
                    formData.overAllPerformance === "Excellent" && "font-bold"
                  } `}
                > */}
                  Excellent
                </span>
                <div className="relative">
                  <input
                    type="radio"
                    id="overAllPerformanceExcellent"
                    className="w-1 h-1 max-w-2xl "
                    name="overAllPerformance"
                    value="Excellent"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        overAllPerformance: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-1 h-full bg-white"></div>
                </div>
              </label>
            </div>{" "}
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5  ${formData.overAllPerformance === "Very Good"
                  ? " border-[#89C442]"
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="overAllPerformanceVeryGood"
                className="flex items-center w-full p-3 cursor-pointer "
              >
                <div className="flex items-center justify-center w-6 h-6 text-[#89C442] text-xl">
                  {formData.overAllPerformance !== "Very Good" ? (
                    <FaRegFaceSmile className="" />
                  ) : (
                    <FaFaceSmile className="" />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Very Good
                </span>
                <div className="relative">
                  <input
                    type="radio"
                    id="overAllPerformanceVeryGood"
                    className="w-1 h-1 max-w-2xl "
                    name="overAllPerformance"
                    value="Very Good"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        overAllPerformance: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-1 h-full bg-white"></div>
                </div>
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5  ${formData.overAllPerformance === "Good"
                  ? " border-yellow-500"
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="overAllPerformanceGood"
                className="flex items-center w-full p-3 cursor-pointer "
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-yellow-500">
                  {formData.overAllPerformance !== "Good" ? (
                    <FaRegFaceMeh />
                  ) : (
                    <FaFaceMeh />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Good
                </span>
                <div className="relative">
                  <input
                    type="radio"
                    id="overAllPerformanceGood"
                    className="w-1 h-1 max-w-2xl "
                    name="overAllPerformance"
                    value="Good"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        overAllPerformance: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-1 h-full bg-white"></div>
                </div>
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5  ${formData.overAllPerformance === "Average"
                  ? " border-orange-600"
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="overAllPerformanceAverage"
                className="flex items-center w-full p-3 cursor-pointer "
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-orange-600 ">
                  {formData.overAllPerformance !== "Average" ? (
                    <FaRegFaceFrown className="" />
                  ) : (
                    <FaFaceFrown />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Average
                </span>
                <div className="relative">
                  <input
                    type="radio"
                    id="overAllPerformanceAverage"
                    className="w-1 h-1 max-w-2xl "
                    name="overAllPerformance"
                    value="Average"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        overAllPerformance: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-1 h-full bg-white"></div>
                </div>
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg  border-2 md:w-1/5 ${formData.overAllPerformance === "Poor"
                  ? " border-red-600 "
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="overAllPerformancePoor"
                className="flex items-center w-full p-3 cursor-pointer "
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-red-600">
                  {formData.overAllPerformance !== "Poor" ? (
                    <FaRegFaceAngry className="" />
                  ) : (
                    <FaFaceAngry />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Poor
                </span>

                <div className="relative">
                  <input
                    type="radio"
                    id="overAllPerformancePoor"
                    className="w-1 h-1 max-w-2xl "
                    name="overAllPerformance"
                    value="Poor"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        overAllPerformance: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                </div>
              </label>
            </div>
          </div>

          <p className="mt-6 mb-2 text-lg font-bold text-center md:mt-12 lg:mt-14 lg:mb-4 md:text-left">
            Basis your service experience, how strongly would you prefer Saboo
            RKS the authorized service center which you visited rather than
            other service centers?
          </p>

          <div className="flex justify-center gap-x-2 lg:justify-start">
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5  ${formData.preferingSabooRKS === "Excellent"
                  ? " border-green-600 "
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="preferingSabooRKSExcellent"
                className="flex items-center w-full p-3 cursor-pointer "
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-green-600 ">
                  {formData.preferingSabooRKS !== "Excellent" ? (
                    <FaRegFaceGrinWide className="" />
                  ) : (
                    <FaFaceGrinWide />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Excellent
                </span>

                <div className="relative">
                  <input
                    type="radio"
                    id="preferingSabooRKSExcellent"
                    className="w-1 h-1 max-w-2xl "
                    name="preferingSabooRKS"
                    value="Excellent"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        preferingSabooRKS: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                </div>
              </label>
            </div>

            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5 ${formData.preferingSabooRKS === "Very Good"
                  ? " border-[#89C442]  "
                  : " border-gray-200  "
                } `}
            >
              <label
                htmlFor="preferingSabooRKSVeryGood"
                className="flex items-center w-full p-3 cursor-pointer "
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-[#89C442]">
                  {formData.preferingSabooRKS !== "Very Good" ? (
                    <FaRegFaceSmile className="" />
                  ) : (
                    <FaFaceSmile className="" />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Very Good
                </span>
                <div className="relative">
                  <input
                    type="radio"
                    id="preferingSabooRKSVeryGood"
                    className="w-1 h-1 max-w-2xl "
                    name="preferingSabooRKS"
                    value="Very Good"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        preferingSabooRKS: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                </div>
              </label>
            </div>

            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5 ${formData.preferingSabooRKS === "Good"
                  ? " border-yellow-500  "
                  : " border-gray-200  "
                } `}
            >
              <label
                htmlFor="preferingSabooRKSGood"
                className="flex items-center w-full p-3 cursor-pointer "
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-yellow-500">
                  {formData.preferingSabooRKS !== "Good" ? (
                    <FaRegFaceMeh className="" />
                  ) : (
                    <FaFaceMeh />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Good
                </span>
                <div className="relative">
                  <input
                    type="radio"
                    id="preferingSabooRKSGood"
                    className="w-1 h-1 max-w-2xl "
                    name="preferingSabooRKS"
                    value="Good"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        preferingSabooRKS: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                </div>
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5 ${formData.preferingSabooRKS === "Average"
                  ? " border-orange-600 "
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="preferingSabooRKSAverage"
                className="flex items-center w-full p-3 cursor-pointer "
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-orange-600 ">
                  {formData.preferingSabooRKS !== "Average" ? (
                    <FaRegFaceFrown className="" />
                  ) : (
                    <FaFaceFrown />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Average
                </span>

                <div className="relative">
                  <input
                    type="radio"
                    id="preferingSabooRKSAverage"
                    className="w-1 h-1 max-w-2xl "
                    name="preferingSabooRKS"
                    value="Average"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        preferingSabooRKS: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                </div>
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5 ${formData.preferingSabooRKS === "Poor"
                  ? " border-red-600 "
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="preferingSabooRKSPoor"
                className="flex items-center w-full p-3 cursor-pointer "
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-red-600 ">
                  {formData.preferingSabooRKS !== "Poor" ? (
                    <FaRegFaceAngry className="" />
                  ) : (
                    <FaFaceAngry />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Poor
                </span>

                <div className="relative">
                  <input
                    type="radio"
                    id="preferingSabooRKSPoor"
                    className="w-1 h-1 max-w-2xl "
                    name="preferingSabooRKS"
                    value="Poor"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        preferingSabooRKS: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                </div>
              </label>
            </div>
          </div>

          <div className="flex items-center justify-center gap-5 mt-10 lg:justify-end">
            {/* <p className="italic ">
              Press <span className="font-bold uppercase">Enter</span>
            </p> */}
            <button
              type="submit"
              className="px-6 py-3 font-bold text-white uppercase bg-gray-900 rounded-md whitespace-nowrap"
            >
              Next
            </button>
          </div>
        </div>{" "}
      </form>
    </div>
  );
};

export default Form1;
