export const bData = [
  {
    title: "Saboo Arena",
    body: "Experience the heart of driving with Maruti Arena. Dive into a wide selection of practical and reliable vehicles that cater to all your mobility needs. Whether you're seeking a dependable family car or a versatile compact vehicle, Maruti Arena provides a diverse range of cars to meet your everyday demands.",
    img: require("../assets/Bussiness/Maruti-Suzuki-Saboo-Rks.png"),
    link: "https://www.saboomaruti.in/",
  },
  {
    title: "Saboo Nexa",
    body: "Discover a world of premium automotive experiences at Maruti Nexa. Explore our exclusive range of sophisticated and technologically advanced cars designed to elevate your driving journey. From elegant design to cutting-edge features, Nexa offers a new standard in automotive luxury and performance.",
    img: require("../assets/Bussiness/Maruti-Suzuki-Nexa-Dealer.png"),
    link: "https://www.saboonexa.in/",
  },
  {
    title: "Saboo True Value",
    body: "True Value is your trusted destination for high-quality, certified pre-owned cars. We offer a carefully curated selection of well-maintained, thoroughly inspected, and certified used vehicles, ensuring peace of mind and reliability in every purchase. With a commitment to transparency and customer satisfaction, True Value redefines the way you buy and own pre-owned cars.",
    img: require("../assets/Bussiness/Maruti-Suzuki-True-Value.png"),
    link: "https://www.sabootruevalue.in/",
  },
  {
    title: "Saboo Autozone",
    body: "Ashok Leyland Dealers in Hyderabad offer Light Commercial Vehicles (LCVs) from 1.25 T to 5 T, with seating options from 13 to 40 seats. These LCVs are powered by Future Ready i-GEN 6 Technology for BS6 compliance. Designed with a focus on ergonomics, driver comfort, and safety, these vehicles provide an excellent customer experience beyond transportation.",
    img: require("../assets/Bussiness/Ashok-Leyland-Saboo-Autozone.png"),
    link: "https://www.sabooautozone.com/",
  },
  {
    title: "Saboo Commercial",
    body: "Commercial vehicle dealers provide a diverse range of trucks, vans, and buses for business and personal needs. They offer financing, maintenance, and parts services, ensuring the longevity and efficiency of fleets. These dealers are one-stop solutions for logistics, construction, and various industries, offering reliable transportation options.",
    img: require("../assets/Bussiness/RKS Commercial Logo.png"),
    link: "https://www.saboocommercial.in/",
  },
  {
    title: "Hotel Inner Circle",
    body: "  Hotel Inner Circle, a leading 3-star hotel in Somajiguda, Hyderabad, is designed to cater to the needs of both business and leisure travelers. Our well-appointed rooms and essential amenities guarantee a comfortable stay, while our strategic location provides easy access to business centers, shopping districts, and popular tourist spots. ",
    img: require("../assets/Bussiness/BroaddCast-Hotel-Inner-Circle.png"),

    link: "https://www.hotelinnercircle.in/",
  },
];

export const bDataLogo = [
  {
    id: 1,
    title: "Saboo Arena",
    body: "Experience the heart of driving with Maruti Arena. Dive into a wide selection of practical and reliable vehicles that cater to all your mobility needs. Whether you're seeking a dependable family car or a versatile compact vehicle, Maruti Arena provides a diverse range of cars to meet your everyday demands.",
    img:require("../assets/Bussiness/Maruti-Suzuki-Saboo-Rks.png"),
    // img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/images/maruti/marutisuzuki/global/maruti-logo-new.jpg?h=35&w=290&la=en&hash=275B37ACCED2135920CAF123F38AE9E1",
    link: "https://www.saboomaruti.in/",
  },
  {
    id: 2,
    title: "Saboo Nexa",
    body: "Discover a world of premium automotive experiences at Maruti Nexa. Explore our exclusive range of sophisticated and technologically advanced cars designed to elevate your driving journey. From elegant design to cutting-edge features, Nexa offers a new standard in automotive luxury and performance.",
    img: require("../assets/Bussiness/Maruti-Suzuki-Nexa-Dealer.png"),
    link: "https://www.saboonexa.in/",
  },
  {
    id: 3,
    title: "Saboo True Value",
    body: "True Value is your trusted destination for high-quality, certified pre-owned cars. We offer a carefully curated selection of well-maintained, thoroughly inspected, and certified used vehicles, ensuring peace of mind and reliability in every purchase. With a commitment to transparency and customer satisfaction, True Value redefines the way you buy and own pre-owned cars.",
    img: require("../assets/Bussiness/Maruti-Suzuki-True-Value.png"),
    link: "https://www.sabootruevalue.in/",
  },
  {
    id: 4,
    title: "Saboo Autozone",
    body: "Ashok Leyland Dealers in Hyderabad offer Light Commercial Vehicles (LCVs) from 1.25 T to 5 T, with seating options from 13 to 40 seats. These LCVs are powered by Future Ready i-GEN 6 Technology for BS6 compliance. Designed with a focus on ergonomics, driver comfort, and safety, these vehicles provide an excellent customer experience beyond transportation.",
    img: require("../assets/Bussiness/Ashok-Leyland-Saboo-Autozone.png"),
    link: "https://www.sabooautozone.com/",
  },
  {
    id: 5,
    title: "Saboo Commercial",
    body: "Commercial vehicle dealers provide a diverse range of trucks, vans, and buses for business and personal needs. They offer financing, maintenance, and parts services, ensuring the longevity and efficiency of fleets. These dealers are one-stop solutions for logistics, construction, and various industries, offering reliable transportation options.",
    img: require("../assets/Bussiness/RKS Commercial Logo.png"),
    link: "https://www.saboocommercial.in/",
  },
  {
    id: 6,
    title: "Hotel Inner Circle",
    body: "  Hotel Inner Circle, a leading 3-star hotel in Somajiguda, Hyderabad, is designed to cater to the needs of both business and leisure travelers. Our well-appointed rooms and essential amenities guarantee a comfortable stay, while our strategic location provides easy access to business centers, shopping districts, and popular tourist spots. ",
    img: require("../assets/Bussiness/BroaddCast-Hotel-Inner-Circle.png"),
    link: "https://www.hotelinnercircle.in/",
  },
  {
    id: 7,
    title: "Saboo Ezone",
    body: "  Saboo Ezone",
    img: require("../assets/Other/sabooezone.png"),
    link: "https://www.hotelinnercircle.in/",
  },
  {
    id: 8,
    title: "Driftly",
    body: " Driftly ",
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/driftly/Driftly_Logo_Primary.png",
    link: "https://www.hotelinnercircle.in/",
  },
  {
    id: 9,
    title: "Saboo Detailing Studio",
    body: " Saboo Detailing Studio ",
    img: require("../assets/Other/SabooDetailingStudio.png"),
    link: "https://www.hotelinnercircle.in/",
  },
];

export const galleryCollection = [
  {
    imageUrl:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/gallery/events/Maruti-Suzuki-Platinum-Dealership-SabooRks.webp",
    title:
      "Recognized for ‘Best Performance Training Status Service’ overall AP & Telangana in the year 2015",
  },
  {
    imageUrl:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/gallery/events/Maruti-Suzuki-Platinum-Dealer-Award-SabooRKS.webp",
    title:
      "RKS Kukatpally recognized as ‘Best Workshop Load Growth - Service & Bodyshop’",
  },
  {
    imageUrl:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/gallery/events/No1-Maruti-Dealer-SABOORKS.webp",
    title:
      "RKS Motor Awarded 'AMPS & Woofer Champion' by Maruti Suzuki in 2015",
  },
  {
    imageUrl:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/gallery/events/Royal-Platinum-Dealer-Award-From-Maruti-Suzuki.webp",
    title:
      "Participated in 'All India Skill Competition', conducted by Maruti Suzuki for Maruti Udyog Limited. between 2004-2005",
  },
  {
    imageUrl:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/gallery/events/SabooRKS-Maruti-Suzuki-Royal-Platinum-Dealer.webp",
    title:
      "RKS Motor Uppal Branch - Awarded ‘Service Quality Award For Good Performance in Q3 - 2007’ by Maruti Suzuki India Limited. - 2007",
  },
  {
    imageUrl:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/gallery/events/SabooRks-Platinum-Dealership-Award-Maruti-Suzuki.webp",
    title:
      "RKS Motor Awarded -'Excellence in Sales' - Category: Best Performance (SSI), Category 1 South II All India Dealer Conference Pattaya - 2002",
  },

  {
    imageUrl:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/gallery/showrooms/Maruti-Suzuki-Cars-Kushaiguda-Location.webp",
    title:
      "RKS Motor recognized for ‘Best Dealers Award' By Bank of Baroda - 2019",
  },
  {
    imageUrl:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/gallery/showrooms/Kushaiguda-Maruti-Suzuki-Showroom.webp",
    title:
      "RKS Motor recognized for ‘Best Dealers Award' By Bank of Baroda - 2019",
  },
  {
    imageUrl:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/gallery/workshops/Maruti-Suzuki-Service-Center-Tadbund.webp",
    title:
      "RKS Motor recognized for ‘Best Dealers Award' By Bank of Baroda - 2019",
  },
  {
    imageUrl:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/gallery/govt-car-deliveries-tricor/Cars-Distribution-to-Minorities.webp",
    title:
      "Awarded for ‘Highest Growth in Retail Parent City Level’ at Regional Dealer Conference Hyderabad - 2010-2011",
  },
  {
    imageUrl:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/gallery/govt-car-deliveries-tricor/TRICOR-Highlights.webp",
    title:
      "RKS Motor - Secunderabad Branch - Recognized for ‘Best Workshop (Maintenance and Washing)’- 2004",
  },
  {
    imageUrl:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/gallery/govt-car-deliveries-tricor/Telangana-SC-ST-BC-Corporation.webp",
    title:
      "RKS Motor recognized for ‘Best Dealers Award' By Bank of Baroda - 2019",
  },
];

export const awards = [
  {
    imageUrl:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/gallery/awards/Maruti-Suzuki-Best-Performance-Training-Status-Service-Saboo-RKS.webp",
    title:
      "Recognized for ‘Best Performance Training Status Service’ overall AP & Telangana in the year 2015",
  },
  {
    imageUrl:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/gallery/awards/Maruti-Suzuki-Best-Work-Shop-Award.webp",
    title:
      "RKS Kukatpally recognized as ‘Best Workshop Load Growth - Service & Bodyshop’",
  },
  {
    imageUrl:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/gallery/awards/Maruti-Suzuki-AMPS-Woofer-Champion-Saboo-RKS.webp",
    title:
      "RKS Motor Awarded 'AMPS & Woofer Champion' by Maruti Suzuki in 2015",
  },
  {
    imageUrl:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/gallery/awards/Maruti-Suzuki-All-India-Skill-Competition-Award-Saboo-RKS.webp",
    title:
      "Participated in 'All India Skill Competition', conducted by Maruti Suzuki for Maruti Udyog Limited. between 2004-2005",
  },
  {
    imageUrl:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/gallery/awards/Maruti-Suzuki-Service-Quality-Award-Saboo-RKS.webp",
    title:
      "RKS Motor Uppal Branch - Awarded ‘Service Quality Award For Good Performance in Q3 - 2007’ by Maruti Suzuki India Limited. - 2007",
  },
  {
    imageUrl:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/gallery/awards/Maruti-Suzuki-Excellence-In-Sales-Award-Saboo-RKS.webp",
    title:
      "RKS Motor Awarded -'Excellence in Sales' - Category: Best Performance (SSI), Category 1 South II All India Dealer Conference Pattaya - 2002",
  },
  {
    imageUrl:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/gallery/awards/Maruti-Suzuki-Regional-Dealer-Award-Saboo-RKS.webp",
    title:
      "Awarded for ‘Highest Growth in Retail Parent City Level’ at Regional Dealer Conference Hyderabad - 2010-2011",
  },
  {
    imageUrl:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/gallery/awards/Maruti-Suzuki-Authorized-Service-Center-Secunderabad-Saboo-RKS.webp",
    title:
      "RKS Motor - Secunderabad Branch - Recognized for ‘Best Workshop (Maintenance and Washing)’- 2004",
  },
  {
    imageUrl:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/gallery/awards/Maruti-Suzuki-Dealers-Award-2019-Saboo-RKS.webp",
    title:
      "RKS Motor recognized for ‘Best Dealers Award' By Bank of Baroda - 2019",
  },
];

export const allBussiness = [
  {
    name: "Automobile",
    link: "/automobile",
    img: require("../assets/Hompage/automobile banner_1.webp"),
  },
  {
    name: "Hospitality",
    link: "/hospitality",
    img: require("../assets/Hompage/hospitality banner.webp"),
  },
  {
    name: "Real Estate",
    link: "/real-estate",
    img:  require("../assets/Hompage/realestate banner.webp"),
  },
  {
    name: "Healthcare & Wellness",
    link: "/healthcare",
    img: require("../assets/Hompage/healthcare banner.webp"),
  },
];

export const profileData = [
  {
    img: require("../assets/Hompage/RKSABOO.jpg"),
    name: "Late Shri R K Saboo",
    desg: "Founder",
    body: "A person of strong principles who believed in Gandhian Philosophy Customer is God. He inculcated Customer Centric Culture which is the corner stone of our organization. We always strived to imbibe the same culture in Saboo RKS operations.",
  },
  {
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Vinay-sir.webp",
    name: "Mr. Vinay Saboo",
    desg: "Managing Director",
    body: "Vinay Saboo’s strategic vision, combined with an unwavering commitment, serves as the driving force propelling Saboo Group to sustained success across various sectors. Under his leadership Saboo RKS has become a household name in Hyderabad's automobile industry ",
  },
  // {
  //   img: "https://saboogroups.com/assets/images/team/Madhu-madam.jpg",
  //   name: "Mrs. MADHU SABOO",
  //   desg: "JOINT MANAGING DIRECTOR",
  //   body: "Madhu Saboo is a dynamic leader celebrated for her operational excellence, meticulous eye for detail, and an uplifting spirit that resonates throughout the organization.",
  // },
  // {
  //   img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Saboo+Groups/Tanay.webp",
  //   name: "MR. TANAY SABOO",
  //   desg: "CHIEF GROWTH OFFICER    ",
  //   body: "Tanay Saboo is a dynamic entrepreneur and product leader deeply passionate about digital transformation and innovation. With a track record of building impactful businesses.",
  // },
  // {
  //   img: "https://saboogroups.com/assets/images/team/Purva-Saboo.jpg",
  //   name: "MRS. PURVA SABOO",
  //   desg: "COS",
  //   body: "Purva Saboo is an accomplished finance specialist, showcasing a remarkable ability to navigate the intricacies of the financial landscape and deliver effective solutions for businesses to make sound financial decisions.    ",
  // },
];

export const bussin1 = [
  {
    link: "https://saboomaruti.in/",
    title: "Saboo Arena",
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/banners/Website+Maruti+Suzuki+Arena+Royal+Platinum+Award+.webp",
  },
  {
    link: "https://saboonexa.in/",
    title: "Saboo Nexa",
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/banners/Facebook_Maruti_Suzuki_No1_SUV_Maker_Saboo_RKS_Motor_October_Still_Banner.webp",
  },
  {
    link: "#",
    title: "Driftly",
    img: require("../assets/Other/driftly.png"),
  },
  {
    link: "#",
    title: "Saboo Detailing",
    img: "https://lh3.googleusercontent.com/p/AF1QipMOhZmyXKVmnTF3MIWuINj3vfHyVtbWaLcuu8Ny=w1080-h608-p-no-v0",
  },
];

export const bussin2 = [
  {
    link: "https://sabooautozone.com/",
    title: "Saboo Autozone",
    img: "https://sabooautozone.com/static/media/banner1.82c8f6ca46c7c425380f.webp",
  },
  {
    link: "https://sabooezone.com/",
    title: "Saboo e-Zone",
    img: require("../assets/Other/sabooezonebanner.png"),
  },
  {
    link: "https://www.saboocommercial.in/",
    title: "Saboo Commercial",
    img: "https://saboocommercial.in/images/banners/newbanner.webp",
  },
];

export const hospitalityBusiness = [
  {
    link: "https://www.hotelinnercircle.in/",
    title: "Hotel Inner Circle",
    img: require("../assets/Other/hospitalitytilebottom.webp"),
  },
];

export const realEstateBusiness = [
  {
    link: "#",
    title: "Saboo Builders  & Developers",
    img: require("../assets/Other/realstatetilebottom.webp"),
  },
];
export const healthcareBusiness = [
  {
    link: "https://kasturbanaturecure1.web.app/",
    title: "Kasturba Nature Cure Hospital",
    img: require("../assets/Other/healthtilebottom.webp"),
  },
];

export const managementData = [
  {
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Vinay-sir.webp",
    name: "Mr. Vinay Saboo",
    desg: "Managing Director",
    body: "Vinay Saboo’s strategic vision, combined with an unwavering commitment, serves as the driving force propelling Saboo Group to sustained success across various sectors. Under his leadership",
    // body: "A visionary entrepreneur with over four decades of experience in the automobile sector. He began his journey as a dealer for Luna mopeds and Kinetic scooters in 1973. He served as a Director in Kinetic Leasing & Finance Ltd., a Kinetic Group Company. His resilience and hard work helped him earn great recognition in the two-wheeler industry. Leveraging his experience, he ventured into the world of four-wheelers by obtaining the prestigious dealership of Maruti Suzuki in 1997. The motto of 'Customers for life' has made Saboo RKS a household name in the automobile industry in Hyderabad.",
  },
  {
    img: require("../assets/Hompage/Madhu-madam.jpg"),
    name: "Mrs. Madhu Saboo",
    desg: "Joint Managing Director",
    body: "Madhu Saboo is a dynamic leader celebrated for her operational excellence, meticulous eye for detail, and an uplifting spirit that resonates throughout the rganization",
  },
  {
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Saboo+Groups/Tanay.webp",
    name: "CA. Tanay Saboo",
    desg: "Chief Growth Officer",
    body: "Tanay Saboo is a dynamic entrepreneur and product leader deeply passionate about digital transformation and innovation. With a track record of building impactful businesses,",
  },
  {
     img: require("../assets/Hompage/Purva-Saboo.jpg"),
    name: "CA. Purva Saboo",
    desg: "COS",
    body: "Purva Saboo is an accomplished finance specialist, showcasing a remarkable ability to navigate the intricacies of the financial landscape and deliver effective solutions for businesses to make sound financial decisions.",
  },
  {
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/about/Pramod.webp",
    name: "CA. Pramod Manudhanya",
    desg: "Director of Finance & Planning",
    body: "Pramod Manudhanya brings specialized expertise to his role, focusing on banking relationships, loan syndications, and project preparation. His responsibilities encompass directing financial planning and strategy,",
  },
  {
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/about/Patel-sir.webp",
    name: "Mr. NK Patel    ",
    desg: "Director of Operations",
    body: "NK Patel spearheads initiatives that streamline workflows and enhance overall productivity. His responsibilities span strategic planning, resource allocations",
  },
  // {
  //   img: "https://saboogroups.com/assets/images/team/Madhu-madam.jpg",
  //   name: "Mrs. MADHU SABOO",
  //   desg: "JOINT MANAGING DIRECTOR",
  //   body: "Madhu Saboo is a dynamic leader celebrated for her operational excellence, meticulous eye for detail, and an uplifting spirit that resonates throughout the organization.",
  // },
  // {
  //   img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Saboo+Groups/Tanay.webp",
  //   name: "MR. TANAY SABOO",
  //   desg: "CHIEF GROWTH OFFICER    ",
  //   body: "Tanay Saboo is a dynamic entrepreneur and product leader deeply passionate about digital transformation and innovation. With a track record of building impactful businesses.",
  // },
  // {
  //   img: "https://saboogroups.com/assets/images/team/Purva-Saboo.jpg",
  //   name: "MRS. PURVA SABOO",
  //   desg: "COS",
  //   body: "Purva Saboo is an accomplished finance specialist, showcasing a remarkable ability to navigate the intricacies of the financial landscape and deliver effective solutions for businesses to make sound financial decisions.    ",
  // },
];
