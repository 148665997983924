import React from "react";
import { bDataLogo } from "../../constant";

import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";

const BrandsSlider = () => {
  return (
    <>
      <div className="container mx-auto select-none">
        <Swiper
          pagination={{
            dynamicBullets: true,
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          allowTouchMove={false}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            1280: {
              slidesPerView: 6,
              spaceBetween: 20,
            },
          }}
          modules={[Pagination, Autoplay]}
          className="mb-10"
        >
          {bDataLogo.map((x,i) => {
            return (
              <SwiperSlide key={x.id}>
              
                {/* Assign a unique key */}
                <div className="flex items-center justify-center h-16 ">
                  <img
                    src={x.img}
                    alt={x.title}
                    srcSet=""
                    className={`my-auto select-none  w-min ${i===4?"max-h-7":"max-h-14"}`}
                  />
                </div>
              </SwiperSlide>
            );
          })}
          <div className="h-10"></div>
        </Swiper>
      </div>
    </>
  );
};

export default BrandsSlider;
