import axios from "axios";
import React, { useEffect } from "react";
import {
  FaFaceAngry,
  FaFaceFrown,
  FaFaceGrinWide,
  FaFaceMeh,
  FaFaceSmile,
  FaRegFaceAngry,
  FaRegFaceFrown,
  FaRegFaceGrinWide,
  FaRegFaceMeh,
  FaRegFaceSmile,
} from "react-icons/fa6";

const Form7 = ({ formData, setFormData, setCurrentStep, link }) => {
  const handleSubmit = (e) => {
    setCurrentStep(8);
    e.preventDefault();
    setTimeout(() => {
      window.scrollTo(0, window.innerHeight * 8);
      if (link) {
        axios.post(
          `https://feedback-git-main-feedback-services-projects.vercel.app/feedback?phone=${formData.phone}`,
          formData
        );
      } else axios.post("https://saboo-nexa.onrender.com/feedback", formData);
    }, 500);
  };

  useEffect(() => {
    if (formData.billExplanation !== "" && formData.transparencyPrice !== "") {
      setCurrentStep(8);
      console.log("testing");
      setTimeout(() => {
        window.scrollTo(0, window.innerHeight * 8);
        if (link) {
          axios.post(
            `https://feedback-git-main-feedback-services-projects.vercel.app/feedback?phone=${formData.phone}`,
            formData
          );
        } else axios.post("https://saboo-nexa.onrender.com/feedback", formData);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.billExplanation, formData.transparencyPrice]); // Removed handleSubmit from dependencies

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="flex items-center justify-center h-screen px-2"
      >
        <div className="w-full max-w-xl md:max-w-3xl ">
          <p className="mb-2 text-lg font-bold text-center lg:mb-4 md:text-left">
            Explanation of charges in bill
          </p>
          <div className="flex justify-center mt-4 mb-16 gap-x-2 lg:justify-start">
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5  ${formData.billExplanation === "Excellent"
                  ? " border-green-600 "
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="billExplanationExcellent"
                className="flex items-center w-full p-3 cursor-pointer "
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-green-600 ">
                  {formData.billExplanation !== "Excellent" ? (
                    <FaRegFaceGrinWide className="" />
                  ) : (
                    <FaFaceGrinWide />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Excellent
                </span>

                <div className="relative">
                  <input
                    type="radio"
                    id="billExplanationExcellent"
                    className="w-1 h-1 max-w-2xl "
                    name="billExplanation"
                    value="Excellent"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        billExplanation: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                </div>
              </label>
            </div>

            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5 ${formData.billExplanation === "Very Good"
                  ? " border-[#89C442]  "
                  : " border-gray-200  "
                } `}
            >
              <label
                htmlFor="billExplanationVeryGood"
                className="flex items-center w-full p-3 cursor-pointer "
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-[#89C442]">
                  {formData.billExplanation !== "Very Good" ? (
                    <FaRegFaceSmile className="" />
                  ) : (
                    <FaFaceSmile className="" />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Very Good
                </span>
                <div className="relative">
                  <input
                    type="radio"
                    id="billExplanationVeryGood"
                    className="w-1 h-1 max-w-2xl "
                    name="billExplanation"
                    value="Very Good"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        billExplanation: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                </div>
              </label>
            </div>

            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5 ${formData.billExplanation === "Good"
                  ? " border-yellow-500  "
                  : " border-gray-200  "
                } `}
            >
              <label
                htmlFor="billExplanationGood"
                className="flex items-center w-full p-3 cursor-pointer "
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-yellow-500">
                  {formData.billExplanation !== "Good" ? (
                    <FaRegFaceMeh className="" />
                  ) : (
                    <FaFaceMeh />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Good
                </span>
                <div className="relative">
                  <input
                    type="radio"
                    id="billExplanationGood"
                    className="w-1 h-1 max-w-2xl "
                    name="billExplanation"
                    value="Good"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        billExplanation: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                </div>
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5 ${formData.billExplanation === "Average"
                  ? " border-orange-600 "
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="billExplanationAverage"
                className="flex items-center w-full p-3 cursor-pointer "
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-orange-600 ">
                  {formData.billExplanation !== "Average" ? (
                    <FaRegFaceFrown className="" />
                  ) : (
                    <FaFaceFrown />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Average
                </span>

                <div className="relative">
                  <input
                    type="radio"
                    id="billExplanationAverage"
                    className="w-1 h-1 max-w-2xl "
                    name="billExplanation"
                    value="Average"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        billExplanation: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                </div>
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5 ${formData.billExplanation === "Poor"
                  ? " border-red-600 "
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="billExplanationPoor"
                className="flex items-center w-full p-3 cursor-pointer "
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-red-600 ">
                  {formData.billExplanation !== "Poor" ? (
                    <FaRegFaceAngry className="" />
                  ) : (
                    <FaFaceAngry />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Poor
                </span>

                <div className="relative">
                  <input
                    type="radio"
                    id="billExplanationPoor"
                    className="w-1 h-1 max-w-2xl "
                    name="billExplanation"
                    value="Poor"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        billExplanation: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                </div>
              </label>
            </div>
          </div>

          <p className="mt-6 mb-2 text-lg font-bold text-center md:mt-12 lg:mt-14 lg:mb-4 md:text-left">
            Transparency in prices of services
          </p>
          <div className="flex justify-center mt-4 mb-16 gap-x-2 lg:justify-start">
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5  ${formData.transparencyPrice === "Excellent"
                  ? " border-green-600 "
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="transparencyPriceExcellent"
                className="flex items-center w-full p-3 cursor-pointer "
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-green-600 ">
                  {formData.transparencyPrice !== "Excellent" ? (
                    <FaRegFaceGrinWide className="" />
                  ) : (
                    <FaFaceGrinWide />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Excellent
                </span>

                <div className="relative">
                  <input
                    type="radio"
                    id="transparencyPriceExcellent"
                    className="w-1 h-1 max-w-2xl "
                    name="transparencyPrice"
                    value="Excellent"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        transparencyPrice: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                </div>
              </label>
            </div>

            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5 ${formData.transparencyPrice === "Very Good"
                  ? " border-[#89C442]  "
                  : " border-gray-200  "
                } `}
            >
              <label
                htmlFor="transparencyPriceVeryGood"
                className="flex items-center w-full p-3 cursor-pointer "
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-[#89C442]">
                  {formData.transparencyPrice !== "Very Good" ? (
                    <FaRegFaceSmile className="" />
                  ) : (
                    <FaFaceSmile className="" />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Very Good
                </span>
                <div className="relative">
                  <input
                    type="radio"
                    id="transparencyPriceVeryGood"
                    className="w-1 h-1 max-w-2xl "
                    name="transparencyPrice"
                    value="Very Good"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        transparencyPrice: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                </div>
              </label>
            </div>

            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5 ${formData.transparencyPrice === "Good"
                  ? " border-yellow-500  "
                  : " border-gray-200  "
                } `}
            >
              <label
                htmlFor="transparencyPriceGood"
                className="flex items-center w-full p-3 cursor-pointer "
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-yellow-500">
                  {formData.transparencyPrice !== "Good" ? (
                    <FaRegFaceMeh className="" />
                  ) : (
                    <FaFaceMeh />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Good
                </span>
                <div className="relative">
                  <input
                    type="radio"
                    id="transparencyPriceGood"
                    className="w-1 h-1 max-w-2xl "
                    name="transparencyPrice"
                    value="Good"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        transparencyPrice: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                </div>
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5 ${formData.transparencyPrice === "Average"
                  ? " border-orange-600 "
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="transparencyPriceAverage"
                className="flex items-center w-full p-3 cursor-pointer "
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-orange-600 ">
                  {formData.transparencyPrice !== "Average" ? (
                    <FaRegFaceFrown className="" />
                  ) : (
                    <FaFaceFrown />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Average
                </span>

                <div className="relative">
                  <input
                    type="radio"
                    id="transparencyPriceAverage"
                    className="w-1 h-1 max-w-2xl "
                    name="transparencyPrice"
                    value="Average"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        transparencyPrice: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                </div>
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5 ${formData.transparencyPrice === "Poor"
                  ? " border-red-600 "
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="transparencyPricePoor"
                className="flex items-center w-full p-3 cursor-pointer "
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-red-600 ">
                  {formData.transparencyPrice !== "Poor" ? (
                    <FaRegFaceAngry className="" />
                  ) : (
                    <FaFaceAngry />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Poor
                </span>

                <div className="relative">
                  <input
                    type="radio"
                    id="transparencyPricePoor"
                    className="w-1 h-1 max-w-2xl "
                    name="transparencyPrice"
                    value="Poor"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        transparencyPrice: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                </div>
              </label>
            </div>
          </div>

          <div className="flex items-center justify-center gap-5 mt-10 lg:justify-end">
            {/* <p className="italic ">
              Press <span className="font-bold uppercase">Enter</span>
            </p> */}
            <button
              type="submit"
              className="px-6 py-3 font-bold text-white uppercase bg-gray-900 rounded-md whitespace-nowrap"
            >
              Next
            </button>
          </div>
        </div>{" "}
      </form>
    </div>
  );
};

export default Form7;
