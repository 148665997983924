import React from "react";

const Trending = () => {
  return (
    <div className="pt-16">
      <div className="container mx-auto px-4 lg:px-[10%] ">
        <div className="">
          <div className="text-5xl mb-7 lg:text-7xl">What’s trending ?</div>
          <div className="mb-6">
            Get the latest news and media updates on our businesses, community{" "}
            <br className="hidden lg:block" />
            initiatives, heritage and people
          </div>
          <div className="cursor-pointer text-[#1D3A8A] font-semibold hover:translate-x-2 duration-300">
            <span className="pr-3">&rarr;</span> Explore the news
          </div>
        </div>
      </div>
      <div className="container grid gap-5 px-1 py-2 mx-auto my-5 md:grid-cols-2 lg:grid-cols-3 lg:gap-10 lg:my-10 ">
        <div className=" overflow-hidden rounded-3xl bg-[#FCFCFE] border-2 hover:shadow-2xl duration-200">
          <img
            src="https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/blog/aug+2023/full-blog-section/Nexa_0000_telanganatoday.webp"
            alt=""
            srcSet=""
          />
          <div className="p-4 lg:p-8 ">
            <div className="text-right ">24 June, 2023</div>
            <div className="mt-3 mb-6 text-xl font-semibold">
              MARUTI SUZUKI’S ICONIC SUV, THE JIMNY HITS HYDERABAD ROADS
            </div>
            <div className="mb-4 lg:mb-6 ">
              Hyderabad's first batch of the iconic SUV from Maruti Suzuki, the
              Jimny was delivered to customers at NEXA Lumbini, RKS Motor Pvt
              Ltd.
            </div>
            <div className="flex items-center cursor-pointer group">
              <div className="pr-4">Read more</div>
              <div className="text-2xl duration-200 group-hover:translate-x-3 text-[#1D3A8A]">
                &rarr;
              </div>
            </div>
          </div>
        </div>
        <div className=" overflow-hidden rounded-3xl bg-[#FCFCFE] border-2 hover:shadow-2xl duration-200">
          <img
            src="https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/blog/images/blog-thumbnail-3.webp"
            alt=""
            srcSet=""
            className="w-full lg:h-60 xl:h-68"
          />
          <div className="p-4 lg:p-8 ">
            <div className="text-right ">16 May, 2023</div>
            <div className="mt-3 mb-6 text-xl font-semibold">
              300 Cars Distributed Under Driver Empowerment Programme
            </div>
            <div className="mb-4 lg:mb-6 ">
              Around 300 cars for unemployed people who are trained in car
              driving were sanctioned by the state government under the program
              in 2020.
            </div>
            <div className="cursor-pointer group">
              <a
                href="https://youtube.com/clip/UgkxpnyYdpl3ouqmfEEOEN0dwMja37bi4-3r?si=sJnKlJAGATb7NO21"
                target="_blank"
                rel="noreferrer"
                className="flex items-center"
              >
                <div className="pr-4">Read more</div>
                <div className="text-2xl duration-200 group-hover:translate-x-3 text-[#1D3A8A]">
                  &rarr;
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className=" overflow-hidden rounded-3xl bg-[#FCFCFE] border-2 hover:shadow-2xl duration-200">
          <img
            src="https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboomaruti/blogScreen/1.webp"
            alt="Mr. Vinay Saboo"
            srcSet=""
          />
          <div className="p-4 lg:p-8 ">
            <div className="text-right ">30 Jan, 2020</div>
            <div className="mt-3 mb-6 text-xl font-semibold">
              From Luna to Maruti - How Vinay Saboo built his business
            </div>
            <div className="mb-4 lg:mb-6 ">
              Today, RKS Saboo has 4 Arena, 2 Nexa showrooms and 7 workshops
              spread across the twin cities of Hyderabad and Secunderabad.
            </div>
            <div className="cursor-pointer group">
              <a
                href="https://youtu.be/xzxj3rTLi10?si=YrgZ1DraMZOpqE7q"
                target="_blank"
                rel="noreferrer"
                className="flex items-center"
              >
                <div className="pr-4">Read more</div>
                <div className="text-2xl duration-200 group-hover:translate-x-3 text-[#1D3A8A]">
                  &rarr;
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trending;
