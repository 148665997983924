import React, { useState } from "react";
import { BiSolidCompass } from "react-icons/bi";
import { hospitalityBusiness } from "../../constant";
import { Helmet } from "react-helmet";

const Hospitality = () => {
  const [show, setShow] = useState(false);

  return (
    <div>
      <Helmet>
        <title>
          Saboo Group Hospitality: Elevating Experiences, Redefining Luxury
        </title>
        <meta
          name="title"
          content="Saboo Group Hospitality: Elevating Experiences, Redefining Luxury"
        />
        <meta
          name="description"
          content="At Saboo Group Hospitality, we are committed to creating unparalleled experiences that transcend expectations. With a portfolio of luxurious hotels and resorts, we redefine hospitality by blending impeccable service with exquisite amenities, ensuring every guest feels cherished and valued. "
        />
      </Helmet>
      <div className="">
        <img
          src={require("../../assets/Other/hospilityBanner.webp")}
          alt=""
          srcSet=""
        />
      </div>
      <div className="bg-[#F5F5F5] py-16 px-4 over">
        <div className="container grid grid-cols-1 gap-2 mx-auto lg:grid-cols-3 sm:px-4 ">
          <div className="pb-8 ">
            <div className="text-5xl font-light lg:text-6xl">
              Saboo Hospitality
            </div>

            <div className="mt-4 text-3xl lg:text-2xl">
              Welcome home, away from home.
            </div>
          </div>
          <div className="space-y-6 text-lg text-justify lg:col-span-2 text-[#273043]">
            <p>
              Nestled within our portfolio is Inner Circle, our boutique
              business hotel that stands as a testament to sophistication and
              tailored luxury. Designed for the discerning business traveler,
              Inner Circle seamlessly blends functionality with indulgence.
            </p>

            <p>
              At Saboo Group Hospitality, our vision is to be the benchmark of
              hospitality innovation. We are dedicated to creating an
              environment where every guest feels not just accommodated but
              truly embraced by the essence of our hospitality.
            </p>
            <p>
              {!show && (
                <span
                  onClick={() => setShow(true)}
                  className="font-semibold text-gray-500 cursor-pointer hover:text-black"
                >
                  Read More...
                </span>
              )}
            </p>

            {show && (
              <>
                <div className="pl-2 lg:pl-4">
                  <h5 className="mb-3 -ml-2 text-xl font-bold lg:text-2xl lg:-ml-4 ">
                    Why Saboo Hospitality?
                  </h5>

                  <ul className="list-disc">
                    <li>
                      <h5 className="font-bold "> Crafting Memorable Stays:</h5>
                      <p className="mb-2">
                        Crafting Memorable Stays: Beyond providing
                        accommodation, we are in the business of crafting
                        memories. Every detail is meticulously curated to ensure
                        that every stay is a narrative of luxury and comfort.
                      </p>
                    </li>
                    <li>
                      <h5 className="font-bold ">
                        Innovation in Every Aspect:
                      </h5>
                      <p className="mb-2">
                        Innovation in Every Aspect: Our commitment to innovation
                        is not confined to a particular aspect. It permeates
                        every facet of our hospitality, ensuring that guests
                        experience the forefront of contemporary comfort,
                        service, and technology.
                      </p>
                    </li>
                    <li>
                      <h5 className="font-bold ">Community-Driven Impact:</h5>
                      <p>
                        Community-Driven Impact: We believe in making a positive
                        impact beyond our hotel walls. Active community
                        engagement and responsible initiatives are integral to
                        our mission, contributing positively to the places we
                        call home.
                      </p>
                    </li>
                  </ul>
                </div>
              </>
            )}

            {/* <div className="cursor-pointer text-[#1D3A8A] font-semibold hover:translate-x-2 duration-300">
              &rarr; Explore our business verticals
            </div> */}
          </div>
        </div>
      </div>
      <div className="container py-10 mx-auto ">
        <div className="pt-4 pb-6 text-4xl font-semibold md:pb-10 md:text-5xl">
          Our Business Site:
        </div>
        {/* <div className="text-xl">Saboo Arena</div> */}
        <div className="grid gap-3 ">
          {hospitalityBusiness.map((x, i) => {
            return (
              <div key={i} className="relative duration-500 hover:scale-95 ">
                <div
                  style={{
                    backgroundImage: `url(${hospitalityBusiness[i].img})`,
                  }}
                  className="relative w-full h-[300px] lg:h-[400px] shadow-lg shadow-gray-600 rounded-3xl bg-bottom bg-contain group"
                >
                  <div className="absolute px-4 py-2 text-3xl font-semibold bg-[#0e0d0d6c] rounded-xl inset-10 w-min h-min sm:whitespace-nowrap text-white">
                    {x.title}
                  </div>
                  <div className="absolute px-4 lg:px-10 py-2 font-semibold duration-200 bg-white rounded-lg cursor-pointer bottom-10 right-10 group-hover:bg-gradient-to-br from-[#213BC9] to-[#00AD8E] group-hover:text-white hover:-translate-y-1 ">
                    <a
                      href={x.link}
                      className="flex items-center gap-2"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BiSolidCompass className="text-2xl xl:text-3xl" />{" "}
                      Explore
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Hospitality;
