import { Routes, Route, useLocation } from "react-router-dom";

import "./App.css";
import Footer from "./components/Other/Footer";
// import Header from "./components/Header";
import Home from "./screen/Home";
import AboutUs from "./components/Wo we are/AboutUs";
import OusStory from "./components/Wo we are/OusStory";
import Managemnet from "./components/Wo we are/Managemnet";
import BusinessVerticals from "./components/What We Do/BusinessVerticals";
import Awards from "./components/Media/Awards";
import Gallery from "./components/Media/Gallery";
import Career from "./screen/Career";
import ContactUs from "./screen/ContactUs";
import CoreValue from "./components/Wo we are/CoreValue";
import Header from "./components/Other/Header";
import Automobile from "./components/What We Do/Automobile";
import Healthcare from "./components/What We Do/Healthcare";
import ReaalEsate from "./components/What We Do/ReaalEsate";
import Hospitality from "./components/What We Do/Hospitality";
// import OurStorytesting from "./components/Wo we are/OurStorytesting";
// import Testing2 from "./components/Wo we are/Testing2";
import NotFound from "./components/Other/NotFound";
// import FinalFeedbackForm from "./components/Feedback/FinalFeedbackForm";
import FeedbackForm from "./components/Feedback/FeedbackForm";

const App = () => {
  const location = useLocation();
  const isAdminRoute =
    location.pathname === "/saboo-rks-service-feedback" ||
    location.pathname.startsWith("/saboo-rks-service-feedback2/") ||
    location.pathname === "/saboo-rks-service-feedback2";

  return (
    <div>
      {!isAdminRoute && <Header />}
      <div className={`${!isAdminRoute && "pt-[70px] lg:pt-20"}`}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<AboutUs />} />
          <Route exact path="/story" element={<OusStory />} />
          <Route exact path="/management" element={<Managemnet />} />
          <Route exact path="/business" element={<BusinessVerticals />} />
          <Route exact path="/core-value" element={<CoreValue />} />
          <Route exact path="/awards" element={<Awards />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/careers" element={<Career />} />
          <Route exact path="/contact" element={<ContactUs />} />
          <Route exact path="/automobile" element={<Automobile />} />
          <Route exact path="/hospitality" element={<Hospitality />} />
          <Route exact path="/real-estate" element={<ReaalEsate />} />
          <Route exact path="/healthcare" element={<Healthcare />} />
          {/* <Route exact path="/testing" element={<OurStorytesting />} /> */}
          {/* <Route exact path="/testing2" element={<Testing2 />} /> */}
          <Route
            exact
            path="/saboo-rks-service-feedback2/:link2/:phoneNumber"
            element={<FeedbackForm />}
          />
          <Route
            exact
            path="/saboo-rks-service-feedback2/:link2"
            element={<FeedbackForm />}
          />
          {/* <Route
            exact
            path="/saboo-rks-service-feedback"
            element={<FinalFeedbackForm />}
          /> */}

          <Route exact path="/*" element={<NotFound />} />
        </Routes>
      </div>
      {!isAdminRoute && <Footer />}
      {/* {!isAdminRoute && <Footer />} */}
    </div>
  );
};

export default App;
