import React from "react";
import { managementData } from "../../constant";
import { Helmet } from "react-helmet";

const Managemnet = () => {
  return (
    <div className="">
      <Helmet>
        <title>
          Saboo Group Management Team - Visionaries Shaping a Bright Future
        </title>
        <meta
          name="title"
          content="Saboo Group Management Team - Visionaries Shaping a Bright Future"
        />
        <meta
          name="description"
          content="Meet the dynamic leadership team at Saboo Group, a collective of visionaries driving innovation, excellence, and sustainable growth in every aspect of our operations."
        />
      </Helmet>
      <div className="container pt-10 mx-auto text-6xl font-light text-center lg:text-7xl ">
        Meet The Board
      </div>
      <div className="container pt-4 pb-10 mx-auto text-xl font-light text-center lg:text-2xl ">
        Meet the Visionaries Leading Saboo Group to Success
      </div>
      {/* <div className="py-16 bg-[#F5F5F5]">
        {managementData.map((x, i) => {
          return (
            <div key={i}>
              {i % 2 === 0 ? (
                <div className="px-4 pt-8 ">
                  <div className="container grid grid-cols-1 gap-4 pb-10 mx-auto lg:grid-cols-3 sm:px-4 md:px-9 ">
                    <div className="flex items-center pb-8 ">
                      <img
                        src={x.img}
                        alt={x.name}
                        srcSet=""
                        className="shadow-lg rounded-3xl shadow-gray-600 "
                      />
                    </div>
                    <div className="space-y-6 text-lg lg:col-span-2 text-[#273043] flex justify-around flex-col">
                      <div>
                        <div className="mb-2 text-5xl font-medium lg:text-6xl">
                          
                          {x.name}
                        </div>
                        <div className="text-3xl lg:text-4xl">{x.desg}</div>
                      </div>
                      <div className="text-justify">{x.body}</div>
                      <div></div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="px-4 pt-6 ">
                  <div className="container flex flex-col-reverse grid-cols-1 gap-4 pb-10 mx-auto lg:flex-none lg:grid lg:grid-cols-3 sm:px-4 md:px-9 ">
                    <div className="space-y-6 text-lg lg:col-span-2 text-[#273043] flex justify-around flex-col">
                      <div>
                        <div className="mb-2 text-5xl font-medium lg:text-6xl">
                          {x.name}
                        </div>
                        <div className="text-3xl lg:text-4xl">{x.desg}</div>
                      </div>
                      <div className="text-justify">{x.body}</div>
                      <div></div>
                    </div>
                    <div className="flex items-center pb-8 lg:justify-end">
                      <img
                        src={x.img}
                        alt={x.name}
                        srcSet=""
                        className="shadow-lg rounded-3xl shadow-gray-600 "
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div> */}
      <div className="">
        <div className="container grid content-center gap-4 pt-4 pb-16 mx-auto md:grid-cols-2 xl:grid-cols-3 lg:gap-8">
          {managementData.map((x, i) => {
            return (
              <div
                key={i}
                className="relative px-4 py-10 overflow-hidden bg-[#F5F5F5] text-white border rounded-xl max-w-[450px] mx-auto"
              >
                <div className="h-[68%] bg-gradient-to-br from-[#213BC9] to-[#00AD8E] absolute bottom-0 left-0 w-full z-0"></div>
                <div className="relative flex justify-center ">
                  <div className="p-0.5 bg-white rounded-full ">
                    <img
                      src={x.img}
                      alt={x.name}
                      srcSet=""
                      className="h-[12rem] md:h-[14rem] lg:h-[16rem] rounded-full select-none"
                    />
                  </div>
                </div>
                <div className="relative pt-2 mx-auto mt-4 text-xl font-semibold text-center uppercase cursor-default">
                  {x.name}
                </div>
                <div className="relative pb-2 text-lg italic text-center">
                  {x.desg}
                </div>
                <div className="relative mt-2 text-center">{x.body}</div>
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className="bg-[#F5F5F5] pt-16 px-4">
        <div className="container grid grid-cols-1 gap-4 pb-10 mx-auto lg:grid-cols-3 sm:px-4 md:px-9 ">
          <div className="flex items-center pb-8 ">
            <img
              src="https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/RKSABOO.webp"
              alt=""
              srcSet=""
              className="shadow-lg rounded-3xl shadow-gray-600 "
            />
          </div>
          <div className="space-y-6 text-lg lg:col-span-2 text-[#273043] flex justify-around flex-col">
            <div>
              <div className="mb-2 text-5xl font-medium lg:text-6xl">
                <span className="text-4xl lg:text-5xl font-extralight">
                  Late
                </span>{" "}
                Shri R K Saboo
              </div>
              <div className="text-3xl lg:text-4xl">Founder</div>
            </div>
            <div className="text-justify">
              A person of strong principles who believed in Gandhian Philosophy
              “Customer is God”. He inculcated Customer Centric Culture which is
              the corner stone of our organization. We always strived to imbibe
              the same culture in Saboo RKS operations.
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div className="bg-[#F5F5F5] pt-16 px-4">
        <div className="container flex flex-col-reverse grid-cols-1 gap-4 pb-10 mx-auto lg:flex-none lg:grid lg:grid-cols-3 sm:px-4 md:px-9 ">
          <div className="space-y-6 text-lg lg:col-span-2 text-[#273043] flex justify-around flex-col">
            <div>
              <div className="mb-2 text-5xl font-medium lg:text-6xl">
                Mr. Vinay Saboo
              </div>
              <div className="text-3xl lg:text-4xl">
                Chairman & Managing Director
              </div>
            </div>
            <div className="text-justify">
              A visionary entrepreneur with over four decades of experience in
              the automobile sector. He began his journey as a dealer for Luna
              mopeds and Kinetic scooters in 1973. He served as a Director in
              Kinetic Leasing & Finance Ltd., a Kinetic Group Company. His
              resilience and hard work helped him earn great recognition in the
              two-wheeler industry. Leveraging his experience, he ventured into
              the world of four-wheelers by obtaining the prestigious dealership
              of Maruti Suzuki in 1997. The motto of 'Customers for life' has
              made Saboo RKS a household name in the automobile industry in
              Hyderabad.
            </div>
            <div></div>
          </div>
          <div className="flex items-center pb-8 lg:justify-end">
            <img
              src="https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Vinay-sir.webp"
              alt=""
              srcSet=""
              className="shadow-lg rounded-3xl shadow-gray-600 "
            />
          </div>
        </div>
      </div>
      <div className="bg-[#F5F5F5] pt-16 px-4">
        <div className="container grid grid-cols-1 pb-10 mx-auto lg:grid-cols-3 sm:px-4 md:px-9 ">
          <div className="flex items-center pb-8 ">
            <img
              src="https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Tanay-sir.webp"
              alt=""
              srcSet=""
              className="rounded-3xl shadow-lg shadow-gray-600  lg:w-[85%] "
            />
          </div>
          <div className="space-y-6 text-lg lg:col-span-2 text-[#273043] flex justify-around flex-col">
            <div>
              <div className="mb-2 text-5xl font-medium lg:text-6xl">
                Mr. Tanay Saboo
              </div>
              <div className="text-3xl lg:text-4xl">
                Director – Sales & Marketing
              </div>
            </div>
            <div className="text-justify">
              He brings a strong financial background, having worked at Ernst & Young for three years and
              Purnartha Investment Advisory for one year. In 2016, he joined the
              family business, Saboo RKS, with a vision to carry forward the
              legacy. His dedication and expertise drive the company to success,
              ensuring the continuation of the tradition of excellence.
            </div>
            <div></div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Managemnet;
