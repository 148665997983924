import React, { useEffect } from "react";
import { awards } from "../../constant";
import { Helmet } from "react-helmet";


function Awards() {
  useEffect(() => {
    // Side effects can be moved here
    return () => {
      // Cleanup code can be placed here if needed
    };
  }, []); // Empty dependency array ensures useEffect runs only once, equivalent to componentDidMount

  return (
    <>
      <Helmet>
        <title>
          Saboo Group Receives Prestigious Awards for Excellence in Automotive
          Innovation
        </title>
        <meta
          name="title"
          content="Saboo Group Receives Prestigious Awards for Excellence in Automotive Innovation"
        />
        <meta
          name="description"
          content="Saboo Group, a leader in the automotive industry, has been honored with prestigious awards recognizing its commitment to excellence and innovation. With a legacy of providing top-quality products and services, Saboo Group continues to set new benchmarks in the automotive sector."
        />
      </Helmet>
      <div className="h-[45vh] lg:h-[50vh] bg-[url(https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/Banner/Saboo+Group+Awards+Banner.webp)]  bg-center"></div>
      <div className="container py-10 mx-auto text-6xl font-light text-center lg:text-7xl lg:whitespace-nowrap ">
        <span className="lg:hidden">Awards</span>
        <span className="hidden lg:block">Awards & Achievements</span>
      </div>

      <div className="container px-2 py-8 mx-auto md:pt-10 md:pb-16">
        <div className="grid grid-cols-1 gap-8 px-5 lg:grid-cols-3 md:px-0 sm:grid-cols-2 ">
          {awards.map((award, index) => (
            <div
              key={index}
              className="overflow-hidden bg-gray-200 border rounded-xl group"
            >
              <div className="overflow-hidden ">
                <img
                  src={award.imageUrl}
                  alt={award.title}
                  className="object-cover overflow-hidden duration-500 select-none saturate-100 group-hover:scale-110 "
                  //   className="h-auto max-w-full mx-auto mb-4 duration-300 hover:scale-105 "
                />
              </div>
              <div className="px-2 pt-4 pb-4 font-semibold text-center lg:text-lg">
                {award.title}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
export default Awards;
