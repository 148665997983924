import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";
import * as Yup from "yup";

const Form9 = ({ formData, setFormData, setCurrentStep }) => {
  return (
    <div className="flex items-center justify-center h-screen px-2">
      <Formik
        initialValues={{ feedback: "" }}
        // onSubmit={(values) => {
        validationSchema={Yup.object({
          feedback: Yup.string().max(
            500,
            "Message must be 500 characters or less"
          ), // Limit message to 300 characters,
        })}
        onSubmit={(values) => {
          console.log(values.feedback);
          setFormData({ ...formData, feedback: values.feedback });
          console.log(formData.feedback);
          if (Link) {
            axios.post(
              `https://feedback-git-main-feedback-services-projects.vercel.app/feedback?phone=${formData.phone}`,
              {
                ...formData,
                feedback: values.feedback,
              }
            );
          } else
            axios.post("https://saboo-nexa.onrender.com/feedback", {
              ...formData,
              feedback: values.feedback,
            });

          toast.success("Thank you for your feedback");
          setCurrentStep(10);
          setFormData({
            vehicleNumber: "",
            name: "",
            phone: "",
            leadFrom: "feedback",
            location: "",

            overAllPerformance: "",
            preferingSabooRKS: "",
            waitTime: null,
            advisorTimeAndAttention: null,
            advisorsUnderstandingWorkingRequirement: "",
            advisorsListenAbility: "",
            advisorsBehavior: "",
            advisorsRecommendationOnWorkRequirement: "",
            advancePerformingWork: "",
            workPerformedOnTheCar: "",
            qualityOfWork: "",
            postServiceWashingAndCleaning: "",
            billExplanation: null,
            transparencyPrice: null,
            recommendation: "",
            feedback: "",
          });
          sessionStorage.removeItem("currentStep");
          sessionStorage.removeItem("personalData");
        }}
        className="max-w-xl mx-auto md:max-w-3xl lg:w-full"
      >
        <div className="w-full max-w-xl md:max-w-3xl ">
          <p className="mb-2 text-lg font-bold text-center lg:mb-4 md:text-left">
            Any other feedback or suggestions? <span className="text-sm text-gray-600">(Optional)</span>
          </p>
          <Form>
            <Field
              as="textarea"
              name="feedback"
              className="w-full h-48 p-3 mt-2 border border-gray-300 rounded-md"
              placeholder="Enter your feedback here"
            />
            <ErrorMessage
              name="feedback"
              component="div"
              className="text-red-700 "
            />
            <p className="mb-2 text-sm text-gray-500">
              Your feedback is valuable to us
            </p>
            <div className="flex items-center justify-center gap-5 mt-10 lg:justify-end">
              <button
                type="submit"
                className="px-6 py-3 font-bold text-white uppercase bg-gray-900 rounded-md whitespace-nowrap "
              >
                Submit
              </button>
            </div>
          </Form>
        </div>
      </Formik>
    </div>
  );
};

export default Form9;
