import axios from "axios";
import React, { useEffect } from "react";

import {
  FaFaceAngry,
  FaFaceFrown,
  FaFaceGrinWide,
  FaFaceMeh,
  FaFaceSmile,
  FaRegFaceAngry,
  FaRegFaceFrown,
  FaRegFaceGrinWide,
  FaRegFaceMeh,
  FaRegFaceSmile,
} from "react-icons/fa6";

const Form4 = ({ formData, setFormData, setCurrentStep, link }) => {
  const handleSubmit = (e) => {
    setCurrentStep(5);
    e.preventDefault();
    setTimeout(() => {
      window.scrollTo(0, window.innerHeight * 5);
      if (link) {
        axios.post(
          `https://feedback-git-main-feedback-services-projects.vercel.app/feedback?phone=${formData.phone}`,
          formData
        );
      } else axios.post("https://saboo-nexa.onrender.com/feedback", formData);
    }, 500);
  };

  useEffect(() => {
    if (
      formData.advisorsBehavior !== "" &&
      formData.advisorsRecommendationOnWorkRequirement !== ""
    ) {
      setCurrentStep(5);
      setTimeout(() => {
        window.scrollTo(0, window.innerHeight * 5);
        if (link) {
          axios.post(
            `https://feedback-git-main-feedback-services-projects.vercel.app/feedback?phone=${formData.phone}`,
            formData
          );
        } else axios.post("https://saboo-nexa.onrender.com/feedback", formData);
      }, 500);
    }
  }, [
    formData.advisorsBehavior,
    formData.advisorsRecommendationOnWorkRequirement,
  ]); // Removed handleSubmit from dependencies

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="flex items-center justify-center h-screen px-2"
      >
        <div className="w-full max-w-xl md:max-w-3xl ">
          <p className="mb-2 text-lg font-bold text-center lg:mb-4 md:text-left">
            Understanding of work required & Behavior of Service advisor
          </p>
          <div className="flex justify-center mt-4 mb-16 gap-x-2 lg:justify-start">
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5  ${formData.advisorsBehavior === "Excellent"
                  ? "  border-green-600 "
                  : " border-gray-200   "
                } `}
            >
              <label
                htmlFor="advisorsBehaviorExcellent"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-green-600">
                  {formData.advisorsBehavior !== "Excellent" ? (
                    <FaRegFaceGrinWide className="" />
                  ) : (
                    <FaFaceGrinWide />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Excellent
                </span>
                <div className="relative">
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                  <input
                    type="radio"
                    id="advisorsBehaviorExcellent"
                    className="w-1 h-1 max-w-2xl "
                    name="advisorsBehavior"
                    value="Excellent"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        advisorsBehavior: e.target.value,
                      });
                    }}
                  />
                </div>{" "}
              </label>
            </div>{" "}
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5  ${formData.advisorsBehavior === "Very Good"
                  ? " border-[#89C442]"
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="advisorsBehaviorVeryGood"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center justify-center w-6 h-6 text-[#89C442] text-xl">
                  {formData.advisorsBehavior !== "Very Good" ? (
                    <FaRegFaceSmile className="" />
                  ) : (
                    <FaFaceSmile className="" />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Very Good
                </span>
                <div className="relative">
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                  <input
                    type="radio"
                    id="advisorsBehaviorVeryGood"
                    className="w-1 h-1 max-w-2xl "
                    name="advisorsBehavior"
                    value="Very Good"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        advisorsBehavior: e.target.value,
                      });
                    }}
                  />
                </div>{" "}
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5  ${formData.advisorsBehavior === "Good"
                  ? " border-yellow-500"
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="advisorsBehaviorGood"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-yellow-500">
                  {formData.advisorsBehavior !== "Good" ? (
                    <FaRegFaceMeh />
                  ) : (
                    <FaFaceMeh />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Good
                </span>
                <div className="relative">
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                  <input
                    type="radio"
                    id="advisorsBehaviorGood"
                    className="w-1 h-1 max-w-2xl "
                    name="advisorsBehavior"
                    value="Good"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        advisorsBehavior: e.target.value,
                      });
                    }}
                  />
                </div>{" "}
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5  ${formData.advisorsBehavior === "Average"
                  ? " border-orange-600"
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="advisorsBehaviorAverage"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-orange-600 ">
                  {formData.advisorsBehavior !== "Average" ? (
                    <FaRegFaceFrown className="" />
                  ) : (
                    <FaFaceFrown />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Average
                </span>
                <div className="relative">
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                  <input
                    type="radio"
                    id="advisorsBehaviorAverage"
                    className="w-1 h-1 max-w-2xl "
                    name="advisorsBehavior"
                    value="Average"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        advisorsBehavior: e.target.value,
                      });
                    }}
                  />
                </div>{" "}
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5  ${formData.advisorsBehavior === "Poor"
                  ? " border-red-600"
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="advisorsBehaviorPoor"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-red-600">
                  {formData.advisorsBehavior !== "Poor" ? (
                    <FaRegFaceAngry className="" />
                  ) : (
                    <FaFaceAngry />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Poor
                </span>
                <div className="relative">
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                  <input
                    type="radio"
                    id="advisorsBehaviorPoor"
                    className="w-1 h-1 max-w-2xl "
                    name="advisorsBehavior"
                    value="Poor"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        advisorsBehavior: e.target.value,
                      });
                    }}
                  />
                </div>{" "}
              </label>
            </div>
          </div>

          <p className="mt-6 mb-2 text-lg font-bold text-center md:mt-12 lg:mt-14 lg:mb-4 md:text-left">
            Service advisor's recommendation regarding the work required upon
            inspection of your car
          </p>

          <div className="flex justify-center mt-4 mb-16 gap-x-2 lg:justify-start">
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5  ${formData.advisorsRecommendationOnWorkRequirement === "Excellent"
                  ? "  border-green-600 "
                  : " border-gray-200   "
                } `}
            >
              <label
                htmlFor="advisorsRecommendationOnWorkRequirementExcellent"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-green-600">
                  {formData.advisorsRecommendationOnWorkRequirement !==
                    "Excellent" ? (
                    <FaRegFaceGrinWide className="" />
                  ) : (
                    <FaFaceGrinWide className="" />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Excellent
                </span>
                <div className="relative">
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                  <input
                    type="radio"
                    id="advisorsRecommendationOnWorkRequirementExcellent"
                    className="w-1 h-1 max-w-2xl "
                    name="advisorsRecommendationOnWorkRequirement"
                    value="Excellent"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        advisorsRecommendationOnWorkRequirement: e.target.value,
                      });
                    }}
                  />
                </div>{" "}
              </label>
            </div>{" "}
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5  ${formData.advisorsRecommendationOnWorkRequirement === "Very Good"
                  ? " border-[#89C442]"
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="advisorsRecommendationOnWorkRequirementVeryGood"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center text-[#89C442] justify-center w-6 h-6 text-xl">
                  {formData.advisorsRecommendationOnWorkRequirement !==
                    "Very Good" ? (
                    <FaRegFaceSmile className="" />
                  ) : (
                    <FaFaceSmile className="" />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Very Good
                </span>
                <div className="relative">
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                  <input
                    type="radio"
                    id="advisorsRecommendationOnWorkRequirementVeryGood"
                    className="w-1 h-1 max-w-2xl "
                    name="advisorsRecommendationOnWorkRequirement"
                    value="Very Good"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        advisorsRecommendationOnWorkRequirement: e.target.value,
                      });
                    }}
                  />
                </div>{" "}
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5  ${formData.advisorsRecommendationOnWorkRequirement === "Good"
                  ? " border-yellow-500"
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="advisorsRecommendationOnWorkRequirementGood"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-yellow-500">
                  {formData.advisorsRecommendationOnWorkRequirement !==
                    "Good" ? (
                    <FaRegFaceMeh className="" />
                  ) : (
                    <FaFaceMeh className="" />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Good
                </span>
                <div className="relative">
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                  <input
                    type="radio"
                    id="advisorsRecommendationOnWorkRequirementGood"
                    className="w-1 h-1 max-w-2xl "
                    name="advisorsRecommendationOnWorkRequirement"
                    value="Good"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        advisorsRecommendationOnWorkRequirement: e.target.value,
                      });
                    }}
                  />
                </div>{" "}
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5  ${formData.advisorsRecommendationOnWorkRequirement === "Average"
                  ? " border-orange-600"
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="advisorsRecommendationOnWorkRequirementAverage"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-orange-600">
                  {formData.advisorsRecommendationOnWorkRequirement !==
                    "Average" ? (
                    <FaRegFaceFrown className="" />
                  ) : (
                    <FaFaceFrown className="" />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Average
                </span>
                <div className="relative">
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                  <input
                    type="radio"
                    id="advisorsRecommendationOnWorkRequirementAverage"
                    className="w-1 h-1 max-w-2xl "
                    name="advisorsRecommendationOnWorkRequirement"
                    value="Average"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        advisorsRecommendationOnWorkRequirement: e.target.value,
                      });
                    }}
                  />
                </div>{" "}
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5  ${formData.advisorsRecommendationOnWorkRequirement === "Poor"
                  ? " border-red-600"
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="advisorsRecommendationOnWorkRequirementPoor"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-red-600 ">
                  {formData.advisorsRecommendationOnWorkRequirement !==
                    "Poor" ? (
                    <FaRegFaceAngry className="" />
                  ) : (
                    <FaFaceAngry className="" />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Poor
                </span>
                <div className="relative">
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                  <input
                    type="radio"
                    id="advisorsRecommendationOnWorkRequirementPoor"
                    className="w-1 h-1 max-w-2xl "
                    name="advisorsRecommendationOnWorkRequirement"
                    value="Poor"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        advisorsRecommendationOnWorkRequirement: e.target.value,
                      });
                    }}
                  />
                </div>{" "}
              </label>
            </div>
          </div>

          <div className="flex items-center justify-center gap-5 mt-10 lg:justify-end">
            {/* <p className="italic ">
              Press <span className="font-bold uppercase">Enter</span>
            </p> */}
            <button
              type="submit"
              className="px-6 py-3 font-bold text-white uppercase bg-gray-900 rounded-md whitespace-nowrap"
            >
              Next
            </button>
          </div>
        </div>{" "}
      </form>
    </div>
  );
};

export default Form4;
