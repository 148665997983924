import React, { useState } from "react";
import CountUp from "react-countup";

const LegacyVision = () => {
  const [show, setShow] = useState(false);
  return (
    <div className="bg-[#F5F5F5] py-16 px-4">
      <div className="container mx-auto sm:px-4 ">
        <div className="grid grid-cols-1 pb-12 lg:grid-cols-3">
          <div className="pb-8 text-6xl font-light lg:text-7xl">
            A legacy. <br /> A vision.
          </div>
          <div className="space-y-6 text-lg text-justify lg:col-span-2 text-[#273043]">
            <p>
              SABOO RKS: Building relationships since 1997. At SABOO RKS Group,
              we consider understanding and anticipating customer needs crucial
              to our continued success. We believe in the "Customers for Life"
              concept and strive to provide the best value for money to our
              customers by offering world-class products and services.
              Dependability, Assurance, Trustworthiness, and Loyalty – values
              synonymous with the SABOO RKS Group – have helped us gain the
              trust of our customers since inception.
            </p>

            <p>
              With an excellent track record for the impeccable quality of our
              service and customer satisfaction, we look forward to growing in a
              dynamic business environment and keeping up with the need to
              anticipate and embrace change, continually reinventing ourselves.
              We strive for customer satisfaction. The testimony of our success
              lies in having the highest percentage of referral sales and repeat
              buyers. We have sold over 120,000 Maruti Suzuki cars to date and
              have serviced over 1,200,000 cars.
              {!show && (
                <span
                  onClick={() => setShow(true)}
                  className="px-2 font-semibold text-gray-500 cursor-pointer hover:text-black"
                >
                  Read More...
                </span>
              )}
            </p>

            {show && (
              <>
                <p>
                  To Provide a Convenient, Hassle-Free Automobile Purchase and
                  Ownership Experience for Our Customers. To Facilitate the Most
                  Cost-Effective Attractive Financial Packages to All Our
                  Customers. To Anticipate Our Customers' Needs, Deliver
                  Innovative and Outstanding Services to Their Delight.
                </p>
                <p>
                  With 45+ Years of Experience in the automobile business, we
                  have been leaders in the automobile industry since 1973 and
                  graduated from 2-wheelers to 4-wheelers with the prestigious
                  dealership of Maruti Suzuki in 1997. At the heart of all this
                  is our people. We take great pride in fostering an inspiring
                  workplace with an agile and high-performance culture. We are
                  also deeply committed to recognizing and valuing diversity
                  across our teams.
                </p>
              </>
            )}

            {/* <div className="cursor-pointer text-[#1D3A8A] font-semibold hover:translate-x-2 duration-300">
              &rarr; Explore our business verticals
            </div> */}
          </div>
        </div>

        <div className="grid gap-2 text-xl text-left lg:grid-cols-3 xl:grid-cols-4 sm:grid-cols-2 lg:gap-4">
          <div className="flex items-center gap-2">
            <div className="text-3xl lg:text-5xl">
              <span className="font-semibold">
                <CountUp start={0} end={8} duration={5} />+
              </span>
            </div>
            Service Centers
          </div>
          <div className="flex items-center gap-2">
            <div className="text-3xl lg:text-5xl">
              <span className="font-semibold">
                <CountUp start={0} end={11} duration={5} />+
              </span>
            </div>
            Outlets
          </div>
          <div className="flex items-center gap-2">
            <div className="text-3xl lg:text-5xl">
              <span className="font-semibold">
                <CountUp start={0} end={100} duration={5} />+
              </span>
            </div>
            Awards Won
          </div>
          <div className="flex items-center gap-2">
            <div className="text-3xl lg:text-5xl">
              <span className="font-semibold">
                <CountUp start={0} end={100} duration={5} />
                k+
              </span>
            </div>
            Happy Clients
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegacyVision;
