import React from "react";
import { Link } from "react-router-dom";

const Activity = () => {
  return (
    <div>
      <div className="container relative mx-auto overflow-hidden shadow-xl rounded-3xl">
        <img
          
          src={require("../../assets/Hompage/core values_1.webp")}
          alt=""
          srcSet=""
          className="w-full"
        />
        <div className="absolute left-0 px-1 py-10 pl-4 mx-2 text-white bg-black md:mx-4 lg:pl-20 lg:bg-opacity-60 bottom-2 sm:bottom-5 lg:bottom-10 rounded-3xl">
          <div className="text-3xl sm:text-4xl lg:text-6xl">Core Value </div>
          <div className="mt-1">
            To Provide A Convenient, Hassle Free Automobile Purchase And
            Ownership Experience For Our Customers.{" "}
            <span className="hidden lg:block ">
              To Facilitate The Most Cost Effective Attractive Financial
              Packages To All Our Customers. To Anticipate Our Customers' Need ,
              Deliver Innovative And Outstanding Services To Their Delight .
            </span>
          </div>
          <div className="mt-1 text-lg font-semibold">
            <Link to="/core-value">Explore &rarr; </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activity;
