import React from "react";
import { Link } from "react-router-dom";

const Leading = () => {
  return (
    <div className="py-16">
      <div className="container mx-auto px-4 lg:px-[10%] ">
        <div className="">
          <div className="text-5xl mb-7 lg:text-7xl">Leading the way</div>
          <div className="mb-6">
            We believe that the fundamental role of leadership is to create and{" "}
            <br className="hidden lg:block" />
            maintain a culture that strengthens the company's core values,{" "}
            <br className="hidden lg:block" />
            encourages employees to do the right thing, and helps drive the{" "}
            <br className="hidden lg:block" />
            company's long-term strategy. All delivered with humility and{" "}
            <br className="hidden lg:block" />
            compassion.
          </div>
          <Link to="/management">
          <div className="cursor-pointer text-[#1D3A8A] font-semibold hover:translate-x-2 duration-300">
            <span className="pr-3">&rarr;</span>Meet our leaders
          </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Leading;
