import React from "react";
import { FaFacebookF, FaPinterestP } from "react-icons/fa";
// import { FaXTwitter } from "react-icons/fa6";
import { IoLogoInstagram } from "react-icons/io";
import { TbWorld } from "react-icons/tb";

const Thankyou = () => {
  return (
    <div className="flex items-center justify-center px-8 sm:h-screen group h-[91vh]">
      <div className="z-10 text-center l">
        <p className="mb-2 font-sans text-4xl font-extrabold lg:mb-4 lg:text-5xl">
          Thank You
        </p>
        <p className="font-bold ">for your valuable feedback</p>
        <div className="my-6">
          <div className="h-0.5 w-full bg-black"></div>
        </div>
        <p className="max-w-lg pb-6 font-bold lg:pb-16">
          Your insights are valuable to us and will help us improve our
          services. We look forward to serving you again.
        </p>
        {/* <button
          onClick={() => setCurrentStep(0)}
          className="px-6 py-3 mt-5 font-sans text-xl font-bold text-white uppercase transition-all duration-200 bg-gray-900 rounded-full whitespace-nowrap hover:scale-95 lg:text-2xl"
        >
          Start Survey
        </button> */}
        <div className="flex w-full max-w-md mx-auto justify-evenly ">
          <a
            href="https://saboomaruti.in/maruti-car-service-centers-near-me"
            target="_blank"
            rel="noreferrer"
            className="flex items-center justify-center w-6 h-6 border-2 border-black rounded-full lg:h-8 lg:w-8 hover:border-blue-500"
          >
            <TbWorld className="text-xl lg:text-2xl hover:text-blue-500" />
          </a>
          <a
            href="https://www.facebook.com/saboorksservicecenter/"
            target="_blank"
            rel="noreferrer"
            className="flex items-center justify-center w-6 h-6 border-2 border-black rounded-full lg:h-8 lg:w-8 hover:border-blue-900 "
          >
            <FaFacebookF className="text-md lg:text-lg hover:text-blue-900" />
          </a>
          {/* <a
            href="https://twitter.com/saboorksmaruti"
            target="_blank"
            rel="noreferrer"
            className="flex items-center justify-center w-6 h-6 border-2 border-black rounded-full lg:h-8 lg:w-8 "
          >
            <FaXTwitter className="text-md lg:text-lg" />
          </a> */}
          <a
            href="https://www.instagram.com/marutisuzukiservicecenter/"
            target="_blank"
            rel="noreferrer"
            className="flex items-center justify-center w-6 h-6 border-2 border-black rounded-full lg:h-8 lg:w-8 hover:border-pink-500 "
          >
            <IoLogoInstagram className="text-xl lg:text-2xl hover:text-pink-500" />
          </a>
          <a
            href="https://in.pinterest.com/rksmaruti0336/?actingBusinessId=735494320303571458"
            target="_blank"
            rel="noreferrer"
            className="flex items-center justify-center w-6 h-6 border-2 border-black rounded-full lg:h-8 lg:w-8 hover:border-red-600 "
          >
            <FaPinterestP className="text-md lg:text-lg hover:text-red-600" />
          </a>
        </div>
      </div>
      <div className="absolute inset-0 left-0 z-0 w-full h-full p-5 overflow-hidden bg-white md:p-12 lg:p-14">
        <div className="w-full h-full bg-[#363388] rounded-[3rem]   bg-opacity-10 ">
          <div className=" -translate-x-1/2 -translate-y-1/2 bg-white rounded-full w-[20rem] h-[20rem] md:w-[25rem] md:h-[25rem] lg:w-[30rem] lg:h-[30rem] p-2  delay-300  duration-500 ">
            <div className="w-full h-full rounded-full bg-[#363388] bg-opacity-10"></div>
          </div>
        </div>

        {/* <img src={require("../../assets/Other/feedback_back.png")} alt="" srcset="" /> */}
      </div>
    </div>
  );
};

export default Thankyou;
