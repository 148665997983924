import axios from "axios";
import React, { useEffect } from "react";
import {
  FaFaceAngry,
  FaFaceFrown,
  FaFaceGrinWide,
  FaFaceMeh,
  FaFaceSmile,
  FaRegFaceAngry,
  FaRegFaceFrown,
  FaRegFaceGrinWide,
  FaRegFaceMeh,
  FaRegFaceSmile,
} from "react-icons/fa6";

const Form2 = ({ formData, setFormData, setCurrentStep, link }) => {
  const handleSubmit = (e) => {
    setCurrentStep(3);
    e.preventDefault();
    setTimeout(() => {
      window.scrollTo(0, window.innerHeight * 3);
      if (link) {
        axios.post(`https://feedback-git-main-feedback-services-projects.vercel.app/feedback?phone=${formData.phone}`, formData);
      } else axios.post("https://saboo-nexa.onrender.com/feedback", formData);
    }, 500);
  };

  useEffect(() => {
    try {
      if (formData.waitTime !== "" && formData.advisorTimeAndAttention !== "") {
        setCurrentStep(3);

        setTimeout(() => {
          window.scrollTo(0, window.innerHeight * 3);
          if (link) {
            axios.post(`https://feedback-git-main-feedback-services-projects.vercel.app/feedback?phone=${formData.phone}`, formData);
          } else
            axios.post("https://saboo-nexa.onrender.com/feedback", formData);
        }, 500);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.waitTime, formData.advisorTimeAndAttention]); // Removed handleSubmit from dependencies

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="flex items-center justify-center h-screen px-2"
      >
        <div className="w-full max-w-xl md:max-w-3xl ">
          <p className="mb-2 text-lg font-bold text-center lg:mb-4 md:text-left">
            Wait time before a service advisor attended you
          </p>
          <div className="flex justify-center mt-4 mb-16 gap-x-2 lg:justify-start">
            <div
              className={`flex gap-5 mb-2 rounded-lg  border-2 md:w-1/5 bg-white ${formData.waitTime === "Excellent"
                  ? "  border-green-600 "
                  : " border-gray-200   "
                } `}
            >
              <label
                htmlFor="waitTimeExcellent"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-green-600">
                  {formData.waitTime !== "Excellent" ? (
                    <FaRegFaceGrinWide className="" />
                  ) : (
                    <FaFaceGrinWide />
                  )}
                </div>

                <span className={`hidden w-full ml-2  select-none md:block  `}>
                  Excellent
                </span>

                <div className="relative">
                  <input
                    type="radio"
                    id="waitTimeExcellent"
                    className="w-1 h-1 max-w-2xl "
                    name="waitTime"
                    value="Excellent"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        waitTime: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                </div>
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5  ${formData.waitTime === "Very Good"
                  ? " border-[#89C442]"
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="waitTimeVeryGood"
                className="flex items-center w-full p-3 cursor-pointer "
              >
                <div className=" items-center justify-center w-6 h-6 text-[#89C442] text-xl  flex">
                  {formData.waitTime !== "Very Good" ? (
                    <FaRegFaceSmile className="" />
                  ) : (
                    <FaFaceSmile className="" />
                  )}
                </div>

                <span className="hidden w-full ml-2 select-none md:block">
                  Very Good
                </span>
                <div className="relative">
                  <input
                    type="radio"
                    id="waitTimeVeryGood"
                    className="w-1 h-1 max-w-2xl "
                    name="waitTime"
                    value="Very Good"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        waitTime: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                </div>
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5  ${formData.waitTime === "Good"
                  ? " border-yellow-500"
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="waitTimeGood"
                className="flex items-center w-full p-3 cursor-pointer "
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-yellow-500">
                  {formData.waitTime !== "Good" ? (
                    <FaRegFaceMeh />
                  ) : (
                    <FaFaceMeh />
                  )}
                </div>

                <span className="hidden w-full ml-2 select-none md:block">
                  Good
                </span>
                <div className="relative">
                  <input
                    type="radio"
                    id="waitTimeGood"
                    className="w-1 h-1 max-w-2xl "
                    name="waitTime"
                    value="Good"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        waitTime: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                </div>
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5  ${formData.waitTime === "Average"
                  ? " border-orange-600"
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="waitTimeAverage"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-orange-600">
                  {formData.waitTime !== "Average" ? (
                    <FaRegFaceFrown className="" />
                  ) : (
                    <FaFaceFrown />
                  )}
                </div>

                <span className="hidden w-full ml-2 select-none md:block">
                  Average
                </span>
                <div className="relative">
                  <input
                    type="radio"
                    id="waitTimeAverage"
                    className="w-1 h-1 max-w-2xl "
                    name="waitTime"
                    value="Average"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        waitTime: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                </div>
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg  border-2 md:w-1/5 ${formData.waitTime === "Poor"
                  ? " border-red-600 "
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="waitTimePoor"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-red-600">
                  {formData.waitTime !== "Poor" ? (
                    <FaRegFaceAngry className="" />
                  ) : (
                    <FaFaceAngry />
                  )}
                </div>

                <span className="hidden w-full ml-2 select-none md:block whitespace-nowrap">
                  Poor
                </span>
                <div className="relative">
                  <input
                    type="radio"
                    id="waitTimePoor"
                    className="w-1 h-1 max-w-2xl border-none outline-none ring-0 focus:ring-0 focus:outline-none"
                    name="waitTime"
                    value="Poor"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        waitTime: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                </div>
              </label>
            </div>
          </div>

          <p className="mt-6 mb-2 text-lg font-bold text-center md:mt-12 lg:mt-14 lg:mb-4 md:text-left">
            Time & attention provided by the Service advisor
          </p>
          <div className="flex justify-center mt-4 mb-16 gap-x-2 lg:justify-start">
            <div
              className={`flex gap-5 mb-2 rounded-lg  border-2 md:w-1/5 bg-white ${formData.advisorTimeAndAttention === "Excellent"
                  ? "  border-green-600 "
                  : " border-gray-200   "
                } `}
            >
              <label
                htmlFor="advisorTimeAndAttentionExcellent"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-green-600">
                  {formData.advisorTimeAndAttention !== "Excellent" ? (
                    <FaRegFaceGrinWide className="" />
                  ) : (
                    <FaFaceGrinWide />
                  )}
                </div>
                <span className={`hidden w-full ml-2  select-none md:block  `}>
                  {/* <span
                  className={`hidden w-full ml-2  select-none md:block ${
                    formData.advisorTimeAndAttention === "Excellent" && "font-bold"
                  } `}
                > */}
                  Excellent
                </span>
                <div className="relative">
                  <input
                    type="radio"
                    id="advisorTimeAndAttentionExcellent"
                    className="w-1 h-1 max-w-2xl "
                    name="advisorTimeAndAttention"
                    value="Excellent"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        advisorTimeAndAttention: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                </div>
              </label>
            </div>{" "}
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5  ${formData.advisorTimeAndAttention === "Very Good"
                  ? " border-[#89C442]"
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="advisorTimeAndAttentionVeryGood"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center justify-center w-6 h-6 text-[#89C442] text-xl">
                  {formData.advisorTimeAndAttention !== "Very Good" ? (
                    <FaRegFaceSmile className="" />
                  ) : (
                    <FaFaceSmile className="" />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Very Good
                </span>
                <div className="relative">
                  <input
                    type="radio"
                    id="advisorTimeAndAttentionVeryGood"
                    className="w-1 h-1 max-w-2xl "
                    name="advisorTimeAndAttention"
                    value="Very Good"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        advisorTimeAndAttention: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                </div>
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5  ${formData.advisorTimeAndAttention === "Good"
                  ? " border-yellow-500"
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="advisorTimeAndAttentionGood"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-yellow-500">
                  {formData.advisorTimeAndAttention !== "Good" ? (
                    <FaRegFaceMeh />
                  ) : (
                    <FaFaceMeh />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Good
                </span>
                <div className="relative">
                  <input
                    type="radio"
                    id="advisorTimeAndAttentionGood"
                    className="w-1 h-1 max-w-2xl "
                    name="advisorTimeAndAttention"
                    value="Good"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        advisorTimeAndAttention: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                </div>
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg border-2 md:w-1/5  ${formData.advisorTimeAndAttention === "Average"
                  ? " border-orange-600"
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="advisorTimeAndAttentionAverage"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-orange-600 ">
                  {formData.advisorTimeAndAttention !== "Average" ? (
                    <FaRegFaceFrown className="" />
                  ) : (
                    <FaFaceFrown />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Average
                </span>
                <div className="relative">
                  <input
                    type="radio"
                    id="advisorTimeAndAttentionAverage"
                    className="w-1 h-1 max-w-2xl "
                    name="advisorTimeAndAttention"
                    value="Average"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        advisorTimeAndAttention: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                </div>
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 bg-white  rounded-lg  border-2 md:w-1/5 ${formData.advisorTimeAndAttention === "Poor"
                  ? " border-red-600 "
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="advisorTimeAndAttentionPoor"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-red-600">
                  {formData.advisorTimeAndAttention !== "Poor" ? (
                    <FaRegFaceAngry className="" />
                  ) : (
                    <FaFaceAngry />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Poor
                </span>
                <div className="relative">
                  <input
                    type="radio"
                    id="advisorTimeAndAttentionPoor"
                    className="w-1 h-1 max-w-2xl "
                    name="advisorTimeAndAttention"
                    value="Poor"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        advisorTimeAndAttention: e.target.value,
                      });
                    }}
                  />
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                </div>
              </label>
            </div>
          </div>

          <div className="flex items-center justify-center gap-5 mt-10 lg:justify-end">
            {/* <p className="italic ">
              Press <span className="font-bold uppercase">Enter</span>
            </p> */}
            <button
              type="submit"
              className="px-6 py-3 font-bold text-white uppercase bg-gray-900 rounded-md whitespace-nowrap"
            >
              Next
            </button>
          </div>

        </div>

      </form>
    </div>
  );
};

export default Form2;
