import React from "react";
import { galleryCollection } from "../../constant";
import { Helmet } from "react-helmet";

const Gallery = () => {
  
  return (
    <div>
      <Helmet>
        <title>
          Saboo Group Gallery - A Pictorial Showcase of Excellence and
          Innovation Across Industries
        </title>
        <meta
          name="title"
          content="Saboo Group Gallery - A Pictorial Showcase of Excellence and Innovation Across Industries"
        />
        <meta
          name="description"
          content="Browse through Saboo Group's impressive gallery, capturing moments of success, innovation, and excellence across our diverse ventures."
        />
      </Helmet>
      <div className="container py-10 mx-auto text-6xl font-light text-center lg:text-7xl ">
        Gallery
      </div>
      <div className="">
        <div className="container grid grid-cols-1 gap-8 px-5 py-8 mx-auto lg:grid-cols-3 md:px-0 sm:grid-cols-2 md:pt-10 md:pb-16">
          {galleryCollection.map((award, index) => (
            <div
              key={index}
              className="overflow-hidden bg-gray-200 border rounded-xl group"
            >
              <div className="overflow-hidden ">
                <img
                  src={award.imageUrl}
                  alt={award.title}
                  className="object-cover h-full overflow-hidden duration-500 select-none saturate-100 group-hover:scale-110"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
