import React, { useState } from "react";
import { BiSolidCompass } from "react-icons/bi";
import { bussin1, bussin2 } from "../../constant";
import { Helmet } from "react-helmet";

const Automobile = () => {
  const [show, setShow] = useState(false);

  return (
    <div>
      <Helmet>
        <title>
          Saboo Group: Driving Innovation in the Automobile Industry
        </title>
        <meta
          name="title"
          content="Saboo Group: Driving Innovation in the Automobile Industry"
        />
        <meta
          name="description"
          content="Explore the diverse automotive offerings of Saboo Group, a leading name synonymous with innovation and excellence in the automotive sector. From top-notch sales and service to cutting-edge technology and customer satisfaction, discover why Saboo Group is your trusted partner on the road."
        />
      </Helmet>
      <div className="">
        <img
          src={require("../../assets/Other/AutomobileBanner.webp")}
          alt=""
          srcSet=""
        />
      </div>
      <div className="bg-[#F5F5F5] py-16 px-4 over">
        <div className="container grid grid-cols-1 gap-2 mx-auto lg:grid-cols-3 sm:px-4 ">
          <div className="pb-8 ">
            <div className="text-5xl font-light lg:text-6xl">
              Saboo Automobile
            </div>

            <div className="mt-4 text-3xl lg:text-2xl">
              Driving Excellence, Powering Journeys
            </div>
          </div>
          <div className="space-y-6 text-lg text-justify lg:col-span-2 text-[#273043]">
            <p>
              Innovation in Motion: Mobility is more than a means of
              transportation; it's an experience. Saboo Group Automotive is at
              the forefront of this evolution, consistently innovating to
              provide mobility solutions that not only meet but exceed
              expectations.
            </p>

            <p>
              Over the course of five decades, Saboo Group has consistently
              excelled in building successful businesses within the automotive
              sector and stands among India's leading auto dealerships having
              sold over 200,000 vehicles and serviced more than 2,000,000
              vehicles to date. At the forefront of the automotive business, the
              group has dealerships for Maruti Suzuki, NEXA, Ashok Leyland, and
              Switch across 45 locations in Telangana encompassing passenger
              vehicles, commercial vehicles, and e-buses segments. Annually, we
              sell more than 15,000 vehicles and provide service to over 150,000
              vehicles.
            </p>
            <p>
              {!show && (
                <span
                  onClick={() => setShow(true)}
                  className="font-semibold text-gray-500 cursor-pointer hover:text-black"
                >
                  Read More...
                </span>
              )}
            </p>

            {show && (
              <>
                <p>
                  Since our inception in 1973, Saboo Group has been synonymous
                  with quality, reliability, and a deep-rooted passion for
                  vehicles. From our humble beginnings to becoming a key player
                  in the automotive landscape, our journey has been defined by a
                  commitment to excellence that stands unwavering. Our
                  commitment to innovation, customer-centric solutions, and a
                  deep understanding of market dynamics have driven us to not
                  just meet industry standards but to exceed them consistently.
                </p>
                <p>
                  At Saboo Group, we envision a future where every journey is an
                  experience, and every vehicle is a conduit to a better
                  tomorrow. Our vision is not confined to selling cars; it's
                  about creating a seamless and memorable automotive experience
                  that transcends traditional expectations.
                </p>
              </>
            )}

            {/* <div className="cursor-pointer text-[#1D3A8A] font-semibold hover:translate-x-2 duration-300">
              &rarr; Explore our business verticals
            </div> */}
          </div>
        </div>
      </div>
      <div className="container py-10 mx-auto ">
        <div className="pt-4 pb-6 text-4xl md:pb-10 md:text-5xl">
          Passenger Vehicles:{" "}
        </div>
        {/* <div className="text-xl">Saboo Arena</div> */}
        <div className="grid gap-3 lg:grid-cols-2">
          {bussin1.map((x, i) => {
            return (
              <div key={i} className="relative duration-500 hover:scale-95 ">
                <div
                  style={{ backgroundImage: `url(${bussin1[i].img})` }}
                  className="relative w-full h-[300px] lg:h-[400px] shadow-lg shadow-gray-600 rounded-3xl bg-center bg-cover group"
                >
                  <div className="absolute px-4 py-2 text-3xl font-semibold bg-[#0e0d0d6c] rounded-xl inset-10 w-min h-min sm:whitespace-nowrap text-white">
                    {x.title}
                  </div>
                  <div className="absolute px-4 lg:px-10 py-2 font-semibold duration-200 bg-white rounded-lg cursor-pointer bottom-10 right-10 group-hover:bg-gradient-to-br from-[#213BC9] to-[#00AD8E] group-hover:text-white hover:-translate-y-1 ">
                    <a
                      href={x.link}
                      className="flex items-center gap-2"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BiSolidCompass className="text-2xl xl:text-3xl" />{" "}
                      Explore
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="py-4 text-4xl md:pb-6 md:pt-16 md:text-5xl">
          Commercial Vehicles:{" "}
        </div>
        <div className="grid gap-3 lg:grid-cols-2">
          {bussin2.map((x, i) => {
            return (
              <div
                key={i}
                className="relative pb-6 duration-500 hover:scale-95 "
              >
                <div
                  style={{ backgroundImage: `url(${bussin2[i].img})` }}
                  className="relative w-full h-[300px] xl:h-[400px] shadow-lg shadow-gray-600 rounded-3xl bg-center bg-cover group"
                >
                  <div className="absolute px-4 py-2 text-xl md:text-3xl font-semibold bg-[#0e0d0d6c] rounded-xl inset-10 w-min h-min whitespace-nowrap text-white">
                    {x.title}
                  </div>
                  <div className="absolute px-4 lg:px-10 py-2 font-semibold duration-200 bg-white rounded-lg cursor-pointer bottom-10 right-10 group-hover:bg-gradient-to-br from-[#213BC9] to-[#00AD8E] group-hover:text-white hover:-translate-y-1 ">
                    <a
                      href={x.link}
                      className="flex items-center gap-2"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BiSolidCompass className="text-2xl xl:text-3xl" />{" "}
                      Explore
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Automobile;
