import axios from "axios";
import React, { useEffect } from "react";

import {
  FaFaceAngry,
  FaFaceFrown,
  FaFaceGrinWide,
  FaFaceMeh,
  FaFaceSmile,
  FaRegFaceAngry,
  FaRegFaceFrown,
  FaRegFaceGrinWide,
  FaRegFaceMeh,
  FaRegFaceSmile,
} from "react-icons/fa6";

const Form5 = ({ formData, setFormData, setCurrentStep, link }) => {
  const handleSubmit = (e) => {
    setCurrentStep(6);
    e.preventDefault();
    setTimeout(() => {
      window.scrollTo(0, window.innerHeight * 6);
      if (link) {
        axios.post(
          `https://feedback-git-main-feedback-services-projects.vercel.app/feedback?phone=${formData.phone}`,
          formData
        );
      } else axios.post("https://saboo-nexa.onrender.com/feedback", formData);
    }, 500);
  };

  useEffect(() => {
    if (
      formData.advancePerformingWork !== "" &&
      formData.workPerformedOnTheCar !== ""
    ) {
      setCurrentStep(6);
      setTimeout(() => {
        window.scrollTo(0, window.innerHeight * 6);
        if (link) {
          axios.post(
            `https://feedback-git-main-feedback-services-projects.vercel.app/feedback?phone=${formData.phone}`,
            formData
          );
        } else axios.post("https://saboo-nexa.onrender.com/feedback", formData);
      }, 500);
    }
  }, [formData.advancePerformingWork, formData.workPerformedOnTheCar]); // Removed handleSubmit from dependencies

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="flex items-center justify-center h-screen px-2"
      >
        <div className="w-full max-w-xl md:max-w-3xl ">
          <p className="mb-2 text-lg font-bold text-center lg:mb-4 md:text-left">
            Explanation of work to be performed in advance
          </p>
          <div className="flex justify-center mt-4 mb-16 gap-x-2 lg:justify-start">
            <div
              className={`flex gap-5 mb-2 rounded-lg  border-2 md:w-1/5 bg-white ${formData.advancePerformingWork === "Excellent"
                  ? " border-green-600 "
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="advancePerformingWorkExcellent"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-green-600 ">
                  {formData.advancePerformingWork !== "Excellent" ? (
                    <FaRegFaceGrinWide className="" />
                  ) : (
                    <FaFaceGrinWide className="" />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Excellent
                </span>
                <div className="relative">
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                  <input
                    type="radio"
                    id="advancePerformingWorkExcellent"
                    className="w-1 h-1 max-w-2xl "
                    name="advancePerformingWork"
                    value="Excellent"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        advancePerformingWork: e.target.value,
                      });
                    }}
                  />
                </div>{" "}
              </label>
            </div>{" "}
            <div
              className={`flex gap-5 mb-2 rounded-lg  border-2 md:w-1/5 bg-white ${formData.advancePerformingWork === "Very Good"
                  ? " border-[#89C442]"
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="advancePerformingWorkVeryGood"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center text-xl text-[#89C442] justify-center w-6 h-6">
                  {formData.advancePerformingWork !== "Very Good" ? (
                    <FaRegFaceSmile className="" />
                  ) : (
                    <FaFaceSmile className="" />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Very Good
                </span>
                <div className="relative">
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                  <input
                    type="radio"
                    id="advancePerformingWorkVeryGood"
                    className="w-1 h-1 max-w-2xl "
                    name="advancePerformingWork"
                    value="Very Good"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        advancePerformingWork: e.target.value,
                      });
                    }}
                  />
                </div>{" "}
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 rounded-lg  border-2 md:w-1/5 bg-white ${formData.advancePerformingWork === "Good"
                  ? " border-yellow-500"
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="advancePerformingWorkGood"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-yellow-500">
                  {formData.advancePerformingWork !== "Good" ? (
                    <FaRegFaceMeh className="" />
                  ) : (
                    <FaFaceMeh className="" />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Good
                </span>
                <div className="relative">
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                  <input
                    type="radio"
                    id="advancePerformingWorkGood"
                    className="w-1 h-1 max-w-2xl "
                    name="advancePerformingWork"
                    value="Good"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        advancePerformingWork: e.target.value,
                      });
                    }}
                  />
                </div>{" "}
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 rounded-lg  border-2 md:w-1/5 bg-white ${formData.advancePerformingWork === "Average"
                  ? " border-orange-600"
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="advancePerformingWorkAverage"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-orange-600">
                  {formData.advancePerformingWork !== "Average" ? (
                    <FaRegFaceFrown className="" />
                  ) : (
                    <FaFaceFrown className="" />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Average
                </span>
                <div className="relative">
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                  <input
                    type="radio"
                    id="advancePerformingWorkAverage"
                    className="w-1 h-1 max-w-2xl "
                    name="advancePerformingWork"
                    value="Average"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        advancePerformingWork: e.target.value,
                      });
                    }}
                  />
                </div>{" "}
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 rounded-lg  border-2 md:w-1/5 bg-white ${formData.advancePerformingWork === "Poor"
                  ? " border-red-600"
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="advancePerformingWorkPoor"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-red-600">
                  {formData.advancePerformingWork !== "Poor" ? (
                    <FaRegFaceAngry className="" />
                  ) : (
                    <FaFaceAngry className="" />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Poor
                </span>
                <div className="relative">
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                  <input
                    type="radio"
                    id="advancePerformingWorkPoor"
                    className="w-1 h-1 max-w-2xl "
                    name="advancePerformingWork"
                    value="Poor"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        advancePerformingWork: e.target.value,
                      });
                    }}
                  />
                </div>{" "}
              </label>
            </div>
          </div>

          <p className="mt-6 mb-2 text-lg font-bold text-center md:mt-12 lg:mt-14 lg:mb-4 md:text-left">
            Explanation about the work performed on the car
          </p>

          <div className="flex justify-center mt-4 mb-16 gap-x-2 lg:justify-start">
            <div
              className={`flex gap-5 mb-2 rounded-lg  border-2 md:w-1/5 bg-white ${formData.workPerformedOnTheCar === "Excellent"
                  ? " border-green-600 "
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="workPerformedOnTheCarExcellent"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-green-600">
                  {formData.workPerformedOnTheCar !== "Excellent" ? (
                    <FaRegFaceGrinWide className="" />
                  ) : (
                    <FaFaceGrinWide className="" />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Excellent
                </span>
                <div className="relative">
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                  <input
                    type="radio"
                    id="workPerformedOnTheCarExcellent"
                    className="w-1 h-1 max-w-2xl "
                    name="workPerformedOnTheCar"
                    value="Excellent"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        workPerformedOnTheCar: e.target.value,
                      });
                    }}
                  />
                </div>{" "}
              </label>
            </div>{" "}
            <div
              className={`flex gap-5 mb-2 rounded-lg  border-2 md:w-1/5 bg-white ${formData.workPerformedOnTheCar === "Very Good"
                  ? " border-[#89C442]"
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="workPerformedOnTheCarVeryGood"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-[#89C442]">
                  {formData.workPerformedOnTheCar !== "Very Good" ? (
                    <FaRegFaceSmile className="" />
                  ) : (
                    <FaFaceSmile className="" />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Very Good
                </span>
                <div className="relative">
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                  <input
                    type="radio"
                    id="workPerformedOnTheCarVeryGood"
                    className="w-1 h-1 max-w-2xl "
                    name="workPerformedOnTheCar"
                    value="Very Good"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        workPerformedOnTheCar: e.target.value,
                      });
                    }}
                  />
                </div>{" "}
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 rounded-lg  border-2 md:w-1/5 bg-white ${formData.workPerformedOnTheCar === "Good"
                  ? " border-yellow-500"
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="workPerformedOnTheCarGood"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-yellow-500">
                  {formData.workPerformedOnTheCar !== "Good" ? (
                    <FaRegFaceMeh className="" />
                  ) : (
                    <FaFaceMeh className="" />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Good
                </span>
                <div className="relative">
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                  <input
                    type="radio"
                    id="workPerformedOnTheCarGood"
                    className="w-1 h-1 max-w-2xl "
                    name="workPerformedOnTheCar"
                    value="Good"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        workPerformedOnTheCar: e.target.value,
                      });
                    }}
                  />
                </div>{" "}
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 rounded-lg  border-2 md:w-1/5 bg-white ${formData.workPerformedOnTheCar === "Average"
                  ? " border-orange-600"
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="workPerformedOnTheCarAverage"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-orange-600">
                  {formData.workPerformedOnTheCar !== "Average" ? (
                    <FaRegFaceFrown className="" />
                  ) : (
                    <FaFaceFrown className="" />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Average
                </span>
                <div className="relative">
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                  <input
                    type="radio"
                    id="workPerformedOnTheCarAverage"
                    className="w-1 h-1 max-w-2xl "
                    name="workPerformedOnTheCar"
                    value="Average"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        workPerformedOnTheCar: e.target.value,
                      });
                    }}
                  />
                </div>{" "}
              </label>
            </div>
            <div
              className={`flex gap-5 mb-2 rounded-lg  border-2 md:w-1/5 bg-white ${formData.workPerformedOnTheCar === "Poor"
                  ? " border-red-600"
                  : " border-gray-200 "
                } `}
            >
              <label
                htmlFor="workPerformedOnTheCarPoor"
                className="flex items-center w-full p-3 cursor-pointer"
              >
                <div className="flex items-center justify-center w-6 h-6 text-xl text-red-600">
                  {formData.workPerformedOnTheCar !== "Poor" ? (
                    <FaRegFaceAngry className="" />
                  ) : (
                    <FaFaceAngry className="" />
                  )}
                </div>
                <span className="hidden w-full ml-2 select-none md:block">
                  Poor
                </span>
                <div className="relative">
                  <div className="absolute top-0 left-0 w-2 h-full bg-white"></div>
                  <input
                    type="radio"
                    id="workPerformedOnTheCarPoor"
                    className="w-1 h-1 max-w-2xl "
                    name="workPerformedOnTheCar"
                    value="Poor"
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        workPerformedOnTheCar: e.target.value,
                      });
                    }}
                  />
                </div>{" "}
              </label>
            </div>
          </div>

          <div className="flex items-center justify-center gap-5 mt-10 lg:justify-end">
            {/* <p className="italic ">
              Press <span className="font-bold uppercase">Enter</span>
            </p> */}
            <button
              type="submit"
              className="px-6 py-3 font-bold text-white uppercase bg-gray-900 rounded-md whitespace-nowrap"
            >
              Next
            </button>
          </div>
        </div>{" "}
      </form>
    </div>
  );
};

export default Form5;
