import React from "react";
import { bData } from "../../constant";
import { Helmet } from "react-helmet";


const BusinessVerticals = () => {
  return (
    <div>
      <Helmet>
        <title>
          Saboo Group Business Ventures - Diversified Excellence for a Brighter
          Future
        </title>
        <meta
          name="title"
          content="Saboo Group Business Ventures - Diversified Excellence for a Brighter Future"
        />
        <meta
          name="description"
          content="Discover Saboo Group's diverse business ventures, each a testament to our commitment to excellence, innovation, and sustainable growth."
        />
      </Helmet>
      <div
        className={`h-[50vh] lg:h-[60vh] bg-[url(https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/Banner/Saboo+Group+Bussiness.webp)]  bg-top`}
      >
       
      </div>
      <div className=" text-center bg-[#F5F5F5]">
        <div className="container py-10 mx-auto text-6xl font-light lg:py-12 lg:text-7xl ">
          Our Business Verticals
        </div>
      </div>
      <div className="container grid gap-5 px-1 py-2 mx-auto my-5 md:grid-cols-2 lg:grid-cols-3 lg:gap-10 lg:my-10 ">
        {bData.map((x, i) => {
          return (
            <div
              key={i}
              className=" overflow-hidden rounded-3xl bg-[#FCFCFE] border-2 hover:shadow-2xl duration-500"
            >
              <div className="flex flex-col justify-between h-full p-4 lg:p-8 group">
                <div>
                  <div className="flex justify-center mb-3">
                    <a href={x.link} target="_blank" rel="noreferrer">
                      <img
                        src={x.img}
                        alt=""
                        srcSet=""
                        className={` ${i===4?"h-6 lg:h-7 my-3":"h-12 lg:h-14"} `}
                      />
                    </a>
                  </div>
                  {/* <div className="mt-3 mb-6 text-3xl font-semibold text-center">
                    {x.title}
                  </div> */}
                  <div className="mb-4 cursor-default lg:mb-6 ">{x.body}</div>
                </div>
                <a
                  href={x.link}
                  target="_blank"
                  rel="noreferrer"
                  className="flex justify-center group-hover:bg-gradient-to-br from-[#213BC9] to-[#00AD8E] group-hover:text-white border rounded-lg group hover:shadow-lg  py-1"
                >
                  <div className="flex items-center cursor-pointer ">
                    <div className="pr-4 ">Visit website</div>
                    <div className="text-2xl duration-200 group-hover:translate-x-3 text-[#1D3A8A] group-hover:text-white ">
                      &rarr;
                    </div>
                  </div>
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BusinessVerticals;
