import React from "react";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  return (
    <div>
      <Helmet>
        <title>
          About Saboo Group - Pioneers in Innovation, Excellence, and Commitment
        </title>
        <meta
          name="title"
          content="About Saboo Group - Pioneers in Innovation, Excellence, and Commitment"
        />
        <meta
          name="description"
          content="Learn about Saboo Group's rich history, our commitment to excellence, and our relentless pursuit of innovation in every aspect of our operations."
        />
      </Helmet>
      <div className="">
        <iframe
          className="w-full aspect-video max-h-[90vh]"
          src="https://www.youtube.com/embed/xzxj3rTLi10?si=4fZmW70BpypSYD_j&amp;rel=0"
          title="Maruti Suzuki| Smart Hybrid Technology"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <div className="bg-[#F5F5F5] py-16 px-4">
        <div className="container mx-auto sm:px-4 ">
          <div className="grid grid-cols-1 lg:grid-cols-5">
            <div className="pb-8 text-6xl font-light lg:text-7xl lg:col-span-2">
              Hello, we are <br /> Saboo RKS.
            </div>
            <div className="space-y-6 text-lg lg:col-span-3 text-[#273043] text-justify">
              <p>
                In the heart of Saboo Group lies a story that unfolds from the
                rich tapestry of 1973, where a family's entrepreneurial spirit
                ignited a journey that transcends conventional success. Welcome
                to a narrative where innovation meets legacy, and the future is
                crafted with purpose.
              </p>
              <p>
                Saboo Group is a family of businesses spanning Automobile,
                Hospitality, Real Estate and Finance. With a workforce exceeding
                1,800 professionals and a turnover surpassing $120M the group
                has established its position as a prominent player in the market
                for its unwavering commitment to excellence.
              </p>
              <p>
                At Saboo Group, our commitment to excellence is the driving
                force behind our success. Through our diversified business
                portfolio and a relentless pursuit of meaningful impact, we
                strive to redefine industries and exceed customer expectations.
                Our unwavering dedication to innovation and societal betterment
                sets us apart in the corporate landscape.
              </p>
              <p>
                We don't just build companies; we craft unforgettable
                experiences with a relentless focus on innovation, delivering
                exceptional value, and contributing to societal betterment. Our
                commitment to sustainability and social responsibility is at the
                heart of everything we do.
              </p>
              <p>
                As we navigate through industries, we do so not just as
                investors but as a family of entrepreneurs, weaving trust,
                support, and collective growth into the fabric of our ventures.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
