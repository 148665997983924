import React, { useEffect, useState } from "react";
// import { FaStar } from "react-icons/fa";

import Form1 from "./Form1";
import Form2 from "./Form2";
import Form3 from "./Form3";
import Form4 from "./Form4";
import Form5 from "./Form5";
import Form6 from "./Form6";
// import PersonalDataForm from "./PersnolDataForm";
import Form7 from "./Form7";
import Form8 from "./Form8";
import { ToastContainer } from "react-toastify";
import WelcomePage from "./WelcomePage";
import Thankyou from "./Thankyou";
import { Helmet } from "react-helmet";
import Form9 from "./Form9";
import { useParams } from "react-router-dom";
import axios from "axios";
import PersonalData from "./PersonalData";
// import axios from "axios";

function FeedbackForm() {
  const [formData, setFormData] = useState({
    vehicleNumber: "",
    name: "",
    phone: "",
    leadFrom: "feedback",
    location: "",
    verifiedCustomer: false,
    overAllPerformance: "",
    preferingSabooRKS: "",
    waitTime: "",
    advisorTimeAndAttention: "",
    advisorsUnderstandingWorkingRequirement: "",
    advisorsListenAbility: "",
    advisorsBehavior: "",
    advisorsRecommendationOnWorkRequirement: "",
    advancePerformingWork: "",
    workPerformedOnTheCar: "",
    qualityOfWork: "",
    postServiceWashingAndCleaning: "",
    billExplanation: "",
    transparencyPrice: "",
    recommendation: "",
    feedback: "",
  });

  let { phoneNumber, link2 } = useParams();

  const [currentStep, setCurrentStep] = useState(-1);
  const [found, setFound] = useState(false);

  useEffect(() => {
    const personalData = JSON.parse(sessionStorage.getItem("personalData"));
    if (
      personalData &&
      phoneNumber === personalData.phone &&
      sessionStorage.getItem("currentStep") === "1"
    ) {
      setCurrentStep(1);
    } else {
      setFormData({
        ...formData,
        phone: "",
        name: "",
        location: "",
        vehicleNumber: "",
      });
      sessionStorage.removeItem("personalData");
      sessionStorage.removeItem("currentStep");
    }

    if (personalData) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...personalData,
      }));
    }
  }, [phoneNumber]); // Added phoneNumber as a dependency

  useEffect(() => {
    if (phoneNumber !== undefined && phoneNumber.length === 10) {
      axios
        .post(
          `https://feedback-git-main-feedback-services-projects.vercel.app/feedback?phone=${phoneNumber}`
        )
        .then((response) => {
          // Handle successful response

          if (response.data.data.phone > 0) {
            console.log(response.data.data.recommendation.charAt(0));
            setFormData({
              ...formData,
              name: response.data.data.name,
              phone: response.data.data.phone,
              location: response.data.data.location,
              vehicleNumber: response.data.data.vehicleNumber,
              overAllPerformance: response.data.data.overAllPerformance,
              preferingSabooRKS: response.data.data.preferingSabooRKS,
              waitTime: response.data.data.waitTime,
              advisorTimeAndAttention:
                response.data.data.advisorTimeAndAttention,
              advisorsUnderstandingWorkingRequirement:
                response.data.data.advisorsUnderstandingWorkingRequirement,
              advisorsListenAbility: response.data.data.advisorsListenAbility,
              advisorsBehavior: response.data.data.advisorsBehavior,
              advisorsRecommendationOnWorkRequirement:
                response.data.data.advisorsRecommendationOnWorkRequirement,
              advancePerformingWork: response.data.data.advancePerformingWork,
              workPerformedOnTheCar: response.data.data.workPerformedOnTheCar,
              qualityOfWork: response.data.data.qualityOfWork,
              postServiceWashingAndCleaning:
                response.data.data.postServiceWashingAndCleaning,
              billExplanation: response.data.data.billExplanation,
              transparencyPrice: response.data.data.transparencyPrice,
              recommendation: response.data.data.recommendation.charAt(0),
              feedback: response.data.data.feedback,
              verifiedCustomer: true,
            });
            setFound(true);
            // setCurrentStep(1);
          } else {
            setFormData({
              ...formData,
              verifiedCustomer: false,
            });
          }
        })
        .catch((error) => {
          // Handle error
          console.error("Error fetching data:", error);
        });
    }
  }, [phoneNumber]);

  useEffect(() => {
    setTimeout(() => {
      setFound(true);
    }, 3000);
  }, []);
  // useEffect(() => {
  //   setTimeout(async () => {
  //     window.scrollTo(0, window.innerHeight * 3);
  //     try {
  //       axios.post("https://saboo-nexa.onrender.com/feedback", formData);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }, 500);
  // }, [currentStep, formData]);

  return (
    <>
      <Helmet>
        <title>Welcome to Saboo RKS Service Customer Satisfaction Survey</title>

        <meta
          name="title"
          content="Welcome to Saboo RKS Service Customer Satisfaction Survey"
        />
        <meta
          name="description"
          content="We value your feedback and aim to improve our service. Please take a few minutes to share your experience with the car servicing at our service center."
        />
      </Helmet>
      <div className="relative bg-[#f5f5f5] scroll-smooth">
        {currentStep === -1 ? (
          <WelcomePage setCurrentStep={setCurrentStep} found={found} />
        ) : currentStep === 0 ? (
          <PersonalData
            formData={formData}
            setFormData={setFormData}
            setCurrentStep={setCurrentStep}
          />
        ) : (
          currentStep !== -1 &&
          currentStep > 0 &&
          currentStep < 10 &&
          currentStep !== 10 && (
            <Form1
              formData={formData}
              link={link2}
              setFormData={setFormData}
              setCurrentStep={setCurrentStep}
            />
          )
        )}

        {currentStep !== 10 &&
          currentStep > 1 &&
          currentStep < 10 &&
          formData.overAllPerformance !== "" &&
          formData.preferingSabooRKS !== "" && (
            <Form2
              formData={formData}
              link={link2}
              setFormData={setFormData}
              setCurrentStep={setCurrentStep}
            />
          )}
        {currentStep !== 10 &&
          currentStep > 1 &&
          currentStep < 10 &&
          formData.overAllPerformance !== "" &&
          formData.preferingSabooRKS !== "" &&
          formData.waitTime !== "" &&
          formData.advisorTimeAndAttention !== "" && (
            <Form3
              formData={formData}
              link={link2}
              setFormData={setFormData}
              setCurrentStep={setCurrentStep}
            />
          )}
        {currentStep !== 10 &&
          currentStep > 1 &&
          currentStep < 10 &&
          formData.overAllPerformance !== "" &&
          formData.preferingSabooRKS !== "" &&
          formData.waitTime !== "" &&
          formData.advisorTimeAndAttention !== "" &&
          formData.advisorsUnderstandingWorkingRequirement !== "" &&
          formData.advisorsListenAbility !== "" && (
            <Form4
              formData={formData}
              link={link2}
              setFormData={setFormData}
              setCurrentStep={setCurrentStep}
            />
          )}
        {currentStep !== 10 &&
          currentStep > 1 &&
          currentStep < 10 &&
          formData.overAllPerformance !== "" &&
          formData.preferingSabooRKS !== "" &&
          formData.waitTime !== "" &&
          formData.advisorTimeAndAttention !== "" &&
          formData.advisorsUnderstandingWorkingRequirement !== "" &&
          formData.advisorsListenAbility !== "" &&
          formData.advisorsBehavior !== "" &&
          formData.advisorsRecommendationOnWorkRequirement !== "" && (
            <Form5
              formData={formData}
              link={link2}
              setFormData={setFormData}
              setCurrentStep={setCurrentStep}
            />
          )}
        {currentStep !== 10 &&
          currentStep > 1 &&
          currentStep < 10 &&
          formData.overAllPerformance !== "" &&
          formData.preferingSabooRKS !== "" &&
          formData.waitTime !== "" &&
          formData.advisorTimeAndAttention !== "" &&
          formData.advisorsUnderstandingWorkingRequirement !== "" &&
          formData.advisorsListenAbility !== "" &&
          formData.advisorsBehavior !== "" &&
          formData.advisorsRecommendationOnWorkRequirement !== "" &&
          formData.advancePerformingWork !== "" &&
          formData.workPerformedOnTheCar !== "" && (
            <Form6
              formData={formData}
              link={link2}
              setFormData={setFormData}
              setCurrentStep={setCurrentStep}
            />
          )}
        {currentStep !== 10 &&
          currentStep > 1 &&
          currentStep < 10 &&
          formData.overAllPerformance !== "" &&
          formData.preferingSabooRKS !== "" &&
          formData.waitTime !== "" &&
          formData.advisorTimeAndAttention !== "" &&
          formData.advisorsUnderstandingWorkingRequirement !== "" &&
          formData.advisorsListenAbility !== "" &&
          formData.advisorsBehavior !== "" &&
          formData.advisorsRecommendationOnWorkRequirement !== "" &&
          formData.advancePerformingWork !== "" &&
          formData.workPerformedOnTheCar !== "" &&
          formData.qualityOfWork !== "" &&
          formData.postServiceWashingAndCleaning !== "" && (
            <Form7
              formData={formData}
              link={link2}
              setFormData={setFormData}
              setCurrentStep={setCurrentStep}
            />
          )}
        {currentStep !== 10 &&
          currentStep > 1 &&
          currentStep < 10 &&
          formData.overAllPerformance !== "" &&
          formData.preferingSabooRKS !== "" &&
          formData.waitTime !== "" &&
          formData.advisorTimeAndAttention !== "" &&
          formData.advisorsUnderstandingWorkingRequirement !== "" &&
          formData.advisorsListenAbility !== "" &&
          formData.advisorsBehavior !== "" &&
          formData.advisorsRecommendationOnWorkRequirement !== "" &&
          formData.advancePerformingWork !== "" &&
          formData.workPerformedOnTheCar !== "" &&
          formData.qualityOfWork !== "" &&
          formData.postServiceWashingAndCleaning !== "" &&
          formData.billExplanation !== "" &&
          formData.transparencyPrice !== "" && (
            <Form8
              formData={formData}
              link={link2}
              setFormData={setFormData}
              setCurrentStep={setCurrentStep}
              currentStep={currentStep}
            />
          )}
        {currentStep === 9 && currentStep > 1 && currentStep < 10 && (
          <Form9
            formData={formData}
            link={link2}
            setFormData={setFormData}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        )}

        {currentStep === 10 && <Thankyou />}

        {currentStep > 0 && currentStep < 9 && (
          <div className="fixed -translate-x-1/2 bottom-7 left-1/2 ">
            <div className="flex justify-between w-48 text-sm">
              <p>{currentStep}/8</p>
              <p>{Math.ceil(currentStep * 12.5)} %</p>
            </div>
            <div className="w-48 h-2 overflow-hidden bg-gray-200 rounded-full">
              <div
                className={`h-full bg-gray-900 ${currentStep === 1
                    ? "w-[13%]"
                    : currentStep === 2
                      ? "w-[25%]"
                      : currentStep === 3
                        ? "w-[38%]"
                        : currentStep === 4
                          ? "w-[50%]"
                          : currentStep === 5
                            ? "w-[63%]"
                            : currentStep === 6
                              ? "w-[75%]"
                              : currentStep === 7
                                ? "w-[88%]"
                                : "w-[100%]"
                  } transition-all duration-300`}
              ></div>
            </div>
          </div>
        )}

        {currentStep > -1 && currentStep !== 10 && (
          <div className="fixed top-0 left-0 w-full py-6 text-lg font-bold text-center border-b lg:py-8 backdrop-blur-xl ">
            {currentStep === 0
              ? "Welcome to Saboo RKS Service Customer Satisfaction Survey"
              : "Saboo RKS Service Feedback"}
          </div>
        )}
        <ToastContainer />
      </div>
    </>
  );
}

export default FeedbackForm;
