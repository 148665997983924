import React, { useState } from "react";
import { BiSolidCompass } from "react-icons/bi";
import { realEstateBusiness } from "../../constant";
import { Helmet } from "react-helmet";

const ReaalEsate = () => {
  const [show, setShow] = useState(false);

  return (
    <div>
      <Helmet>
        <title>
          Saboo Group: Your Trusted Partner in Real Estate Development
        </title>
        <meta
          name="title"
          content="Saboo Group: Your Trusted Partner in Real Estate Development"
        />
        <meta
          name="description"
          content="Trust Saboo Group for your next real estate investment and unlock a world of possibilities."
        />
      </Helmet>
      <div className="">
        <img
          src={require("../../assets/Other/realEstateBanner.webp")}
          alt=""
          srcSet=""
        />
      </div>
      <div className="bg-[#F5F5F5] py-16 px-4 over">
        <div className="container grid grid-cols-1 gap-2 mx-auto lg:grid-cols-3 sm:px-4 ">
          <div className="pb-8 ">
            <div className="text-5xl font-medium lg:text-6xl">
              Saboo Real Esate
            </div>

            <div className="mt-4 text-3xl lg:text-2xl">
              {/* Building the future with sustainable and visionary projects. Commercial, projects & developments. */}
              Transforming Landscapes, Elevating Lifestyles
            </div>
          </div>
          <div className="space-y-6 text-lg text-justify lg:col-span-2 text-[#273043]">
            <p>
              Step into a realm where architectural innovation meets sustainable
              living. Saboo Group Real Estate is not just a developer; we are
              curators of future living experiences. Explore a world where every
              project is a masterpiece, and every development is a testament to
              our unwavering commitment to excellence.
            </p>

            <p>
              Our vision is rooted in the belief that real estate is not just
              about constructing structures but about shaping lifestyles. At
              Saboo Group, we envision a future where our developments redefine
              the very fabric of modern living. Our aim is to lead the industry
              with innovative, sustainable, and aesthetically pleasing projects
              that resonate with the needs and aspirations of the contemporary
              dweller.
            </p>
            <p>
              {!show && (
                <span
                  onClick={() => setShow(true)}
                  className="font-semibold text-gray-500 cursor-pointer hover:text-black"
                >
                  Read More...
                </span>
              )}
            </p>

            {show && (
              <>
                <div className="pl-2 lg:pl-4">
                  <h5 className="mb-3 -ml-2 text-xl font-bold lg:text-2xl lg:-ml-4">
                    Flagship Projects:
                  </h5>

                  <ul className="list-disc">
                    <li>
                      <h5 className="font-bold "> Madhapur Lake Plaza</h5>
                      <p className="mb-2">
                        This distinguished waterfront commercial project offers
                        more than just office spaces; it presents a rare
                        opportunity to redefine your business's outlook,
                        literally and figuratively.
                      </p>
                    </li>
                    <li>
                      <h5 className="font-bold ">Deepak Industries</h5>
                      <p className="mb-2">
                        A meticulously designed, 100,000 sqft multistory car
                        park facility. With a capacity for &gt; 500 vehicles,
                        this internal marvel showcases our commitment to
                        cutting-edge solutions
                      </p>
                    </li>
                    {/* <li>
                      <h5 className="font-bold ">Community-Driven Impact:</h5>
                      <p>
                        Community-Driven Impact: We believe in making a positive
                        impact beyond our hotel walls. Active community
                        engagement and responsible initiatives are integral to
                        our mission, contributing positively to the places we
                        call home.
                      </p>
                    </li> */}
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="container py-10 mx-auto ">
        <div className="pt-4 pb-6 text-4xl md:pb-10 md:text-5xl">
          Our Business Site:
        </div>
        {/* <div className="text-xl">Saboo Arena</div> */}
        <div className="grid gap-3 ">
          {realEstateBusiness.map((x, i) => {
            return (
              <div key={i} className="relative duration-500 hover:scale-95 ">
                <div
                  style={{
                    backgroundImage: `url(${realEstateBusiness[i].img})`,
                  }}
                  className="relative w-full h-[300px] lg:h-[400px] shadow-lg shadow-gray-600 rounded-3xl bg-center bg-cover group"
                >
                  <div className="absolute px-4 py-2 text-3xl font-semibold bg-[#0e0d0d6c] rounded-xl inset-10 w-min h-min sm:whitespace-nowrap text-white">
                    {x.title}
                  </div>
                  <div className="absolute px-4 lg:px-10 py-2 font-semibold duration-200 bg-white rounded-lg cursor-pointer bottom-10 right-10 group-hover:bg-gradient-to-br from-[#213BC9] to-[#00AD8E] group-hover:text-white hover:-translate-y-1 ">
                    {/* <a
                      href={x.link}
                      className="flex items-center gap-2"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BiSolidCompass className="text-2xl xl:text-3xl" />{" "}
                      Explore
                    </a> */}
                    <div className="flex items-center gap-2">
                      <BiSolidCompass className="text-2xl xl:text-3xl" />{" "}
                      Explore
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ReaalEsate;
