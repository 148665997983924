import React, { useRef } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import { GrFormPrevious, GrFormNext } from "react-icons/gr";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./HomeSlider.css";

// import required modules
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import { profileData } from "../../constant";

export default function LeadingSlider() {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <div className="container mx-auto mb-10 overflow-visible select-none">
      <Swiper
        // pagination={{
        //   type: "fraction",
        // }}
        // loop={true}
        navigation={{
          nextEl: navigationNextRef.current,
          prevEl: navigationPrevRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        // autoplay={{
        //   delay: 4000,
        //   disableOnInteraction: false,
        // }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1280: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
        }}
        modules={[Pagination, Navigation, Autoplay]}
        className="relative overflow-visible mySwiper"
      >
        {profileData.map((x, i) => {
          return (
            <SwiperSlide
              key={i}
              className="relative h-full overflow-hidden border-2 rounded-3xl group "
            >
              <div className=" overflow-hidden  bg-[#FCFCFE]  duration-200 sm:flex group-hover:shadow-2xl hover:shadow">
                <div className="">
                  <img src={x.img} alt={x.name} srcSet="" />
                </div>

                <div className="p-4 my-auto space-y-3 text-left sm:w-1/2 lg:p-6">
                  <div className="h-0.5 bg-[#1D3A8A] mb-6 group-hover:w-[100%] w-[50%] duration-300"></div>
                  <div className="mb-3 text-xl font-semibold uppercase">
                    {x.name}
                  </div>
                  <div className="uppercase">{x.desg}</div>
                  <div className="text-sm">{x.body}</div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}

        {/* <div className="absolute z-10 text-white bottom-10 right-44 md:bottom-[83px]">
          2/3
         </div> */}
        <div className="pt-16 -z-10 lg:pt-4"></div>
        <div className="absolute z-10 flex border divide-x shadow-md bottom-4 right-2 shadow-black ">
          <div
            ref={navigationPrevRef}
            className=" p-1.5 sm:py-2 px-3 cursor-pointer bg-white hover:bg-gradient-to-br from-[#213BC9] to-[#00AD8E] hover:text-white"
          >
            {/* <GrFormPrevious /> */} &lt;
          </div>
          <div
            ref={navigationNextRef}
            className=" p-1.5 sm:py-2 px-3 cursor-pointer bg-white hover:bg-gradient-to-br from-[#213BC9] to-[#00AD8E] hover:text-white "
          >
            &gt;
          </div>
        </div>
      </Swiper>
    </div>
  );
}
