import React, { useState } from "react";
import { PiTreeStructure } from "react-icons/pi";
import { BiSolidCompass } from "react-icons/bi";

import { MdOutlineArrowRight } from "react-icons/md";
import { allBussiness } from "../../constant";
import { Link } from "react-router-dom";

const Bussiness = () => {
  const [selected, setSelected] = useState(0);

  return (
    <div className="px-4 py-16">
      <div className="container grid mx-auto mb-6 md:grid-cols-4">
        <div>
          <div className="flex items-center gap-2 mb-2 text-xl font-semibold">
            <PiTreeStructure className="text-2xl rotate-90" /> Our Business
            Verticals
          </div>
          <div className="ml-8">
            {allBussiness.map((x, i) => (
              <div
                key={i}
                className={`${
                  selected === i
                    ? "text-[#1D3A8A] font-semibold text-lg "
                    : " hover:text-[#1D3A8A] hover:font-semibold pl-5"
                } py-2 cursor-pointer uppercase select-none flex items-center`}
                onClick={() => setSelected(i)}
              >
                {selected === i && <MdOutlineArrowRight />} {x.name}
                <div></div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-span-3 group">
          <div
            // style={{  backgroundImage: `url(${allBussiness[selected].img})` }}
            className="relative w-full overflow-hidden shadow-lg shadow-gray-600 rounded-xl lg:rounded-3xl"
          >
            <img src={allBussiness[selected].img} alt="" srcSet="" className="w-full" />
            <div className="absolute px-4 py-2 font-semibold duration-200 bg-white rounded-lg cursor-pointer bottom-10 right-10 group-hover:bg-gradient-to-br from-[#213BC9] to-[#00AD8E] group-hover:text-white hover:-translate-y-1 ">
              {/* <a
                href={allBussiness[selected].link}
                className="flex items-center gap-2"
                target="_blank"
                rel="noreferrer"
              >
                <BiSolidCompass className="text-2xl xl:text-3xl" /> Explore
              </a> */}
              
              <Link to={allBussiness[selected].link} className="flex items-center ">
                <BiSolidCompass className="mr-2 text-2xl xl:text-3xl" /> Explore
              </Link>
            </div>
          </div>
          {/* <div
            // style={{  backgroundImage: `url(${allBussiness[selected].img})` }}
            className="relative w-full h-[300px]  shadow-lg shadow-gray-600 rounded-xl lg:rounded-3xl overflow-hidden"
          >
            <img src={allBussiness[selected].img} alt="" srcSet="" className="w-full h-full" />
            <div className="absolute px-4 py-2 font-semibold duration-200 bg-white rounded-lg cursor-pointer bottom-10 right-10 group-hover:bg-gradient-to-br from-[#213BC9] to-[#00AD8E] group-hover:text-white hover:-translate-y-1 ">
              {/* <a
                href={allBussiness[selected].link}
                className="flex items-center gap-2"
                target="_blank"
                rel="noreferrer"
              >
                <BiSolidCompass className="text-2xl xl:text-3xl" /> Explore
              </a> 
              
              <Link to={allBussiness[selected].link} className="flex items-center ">
                <BiSolidCompass className="mr-2 text-2xl xl:text-3xl" /> Explore
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Bussiness;
